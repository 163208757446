import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { setIsComingFromHome } from "redux/configuration/configuration.actions";
import { IHook } from "./useRedirect.types";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { AppThunkDispatch } from "redux/configuration/configuration.types";

export const useRedirect: IHook = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const { pathname } = useLocation();
  const { entityId } = useParams();
  const navigate = useNavigate();
  const { isComingFromHome } = useSelector(
    (state: IConfigurationState) => state
  );

  useEffect(() => {
    if (pathname === `/${entityId}/` || pathname === `/${entityId}`) {
      dispatch(setIsComingFromHome());
    } else if (!isComingFromHome) {
      navigate(`/${entityId}`);
    }
  }, [dispatch, pathname, entityId, isComingFromHome, navigate]);
};
