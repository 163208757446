import { FC } from "react";
import { IAPIPicture } from "redux/configuration/configuration.interface";

export interface SpinGameSlotProps {
  sortedPictures: IAPIPicture[];
  isSpinning: boolean;
  result: number | null;
  index: number;
}

export const SpinGameSlot: FC<SpinGameSlotProps> = ({
  sortedPictures,
  isSpinning,
  result,
  index,
}) => {
  return (
    <div className="spin-game-slot bg-white">
      {!!sortedPictures?.length && (
        <div
          className={`spin-game-slot-imgs f f-direction-column f-jc-start f-ai-center ${
            isSpinning ? `spin-game-slot-spinning` : ""
          } ${result != null ? `spin-game-slot-result-${result}` : ""}`}
          style={{ animationIterationCount: `${4 + index}` }}
        >
          {/* The image below is a placeholder to see the a fragment of image when displaying the FIRST image */}
          <div
            key="spin-game-slot-img-pre"
            className="spin-game-slot-img spin-game-slot-img-pre f f-center"
          >
            <img
              src={sortedPictures[sortedPictures.length - 1]?.pbu}
              alt={`spin-game-slot-img-pre`}
            />
          </div>

          {sortedPictures.map((img, index) => (
            <div key={img?.pbu} className="spin-game-slot-img f f-center">
              <img src={img?.pbu} alt={`spin-game-slot-img-${index}`} />
            </div>
          ))}

          {/* The image below is a placeholder to see the a fragment of image when displaying the LAST image 
          Use the [1] image and not the [0] because the [0] is the success icon
          */}
          <div
            key="spin-game-slot-img-post"
            className="spin-game-slot-img spin-game-slot-img-post f f-center"
          >
            <img src={sortedPictures[1]?.pbu} alt={`spin-game-slot-img-post`} />
          </div>
        </div>
      )}
    </div>
  );
};
