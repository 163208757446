export enum ErrorsType {
  ALREADY_CLAIMED = "alreadyClaimed",
  NOT_FOUND = "notFound",
  EXPIRED = "expired",
  NOT_AVAILABLE = "notAvailable",
}

export interface IProps {
  errorType: ErrorsType;
  claimAvailableDate?: string | undefined;
}
