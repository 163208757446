import { createStore, applyMiddleware, AnyAction } from "redux";
import thunk from "redux-thunk";
import { configurationReducer } from "./configuration/configuration.reducer";
import { composeWithDevTools } from "@redux-devtools/extension";

export type IDispatch = (arg: AnyAction) => AnyAction;

export const store = createStore(
  configurationReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
