import { FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ErrorsType, IProps } from "./core/modalCollectError.types";
import Button from "components/Inputs/Button";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { resetGame } from "redux/game/game.actions";
import { AppThunkDispatch } from "redux/configuration/configuration.types";

const ModalCollectError: FC<IProps> = ({ errorType, claimAvailableDate }) => {
  const { t } = useTranslation("modals");
  const { entityId } = useParams();
  const dispatch: AppThunkDispatch = useDispatch();
  const navigate = useNavigate();

  const handleCtaClick = useCallback(() => {
    dispatch(resetGame(entityId));
    navigate(`/${entityId}`);
  }, [dispatch, entityId, navigate]);

  return (
    <div className="modal__content pt-10 pb-10">
      <div className="modal__img">
        <img
          src="/static/images/modals/exclamation-mark.png"
          alt="check emoji"
        />
      </div>
      <h2 className="modal__title p-2 mt-2">
        <Trans ns="modals" i18nKey={`collect.${errorType}Collect.title`} />
      </h2>
      <p className="mb-2">
        <Trans
          ns="modals"
          i18nKey={`collect.${errorType}Collect.subtitle`}
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <div className="pb-5">
        <p>
          <Trans
            ns="modals"
            i18nKey={`collect.${errorType}Collect.subtitle2`}
            components={[<span className="c-primary fw-700"></span>]}
          />
        </p>
        {errorType === "notAvailable" && (
          <p className="fw-600">{claimAvailableDate}</p>
        )}
      </div>
      {errorType === ErrorsType.EXPIRED && (
        <Button
          className="modal__btn"
          onClick={handleCtaClick}
          text={t("prize.confirm.CTACantPlayAgain")}
        />
      )}
    </div>
  );
};

export default ModalCollectError;
