import Icon from "components/Icon";
import TextInput from "components/Inputs/TextInput";
import Restaurant from "components/Restaurant";
import { FC, useMemo, useState } from "react";
import { Icons } from "utils/constants/icons";
import { IProps } from "./core/modalConfigPickRestaurant.types";
import {
  IAPIEstablishment,
  IConfigurationState,
} from "redux/configuration/configuration.interface";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import searchNormalizedString from "utils/misc/searchNormalizedString";

const MIN_SEARCH_LENGTH = 2;

const ModalConfigPickRestaurant: FC<IProps> = ({
  hideModal,
  establishments,
  onRestaurantSelected,
}) => {
  const [search, setSearch] = useState<string>("");
  const { configuration } = useSelector((state: IConfigurationState) => state);
  const { t } = useTranslation("modals");

  const filteredEstablishments = useMemo(() => {
    return establishments.filter((establishment: IAPIEstablishment) => {
      return (
        searchNormalizedString(search, establishment?.n) ||
        searchNormalizedString(search, establishment?.sa) ||
        searchNormalizedString(search, establishment?.sat) ||
        searchNormalizedString(search, establishment?.c) ||
        searchNormalizedString(search, establishment?.pc)
      );
    });
  }, [establishments, search]);

  const onFilteredEstablishmentClick = (filtredEstablishmentIndex: number) => {
    const establishmentIndex = establishments.findIndex((etablishment) => {
      return (
        etablishment?.cid ===
        filteredEstablishments[filtredEstablishmentIndex]?.cid
      );
    });
    onRestaurantSelected(establishmentIndex);
  };

  return (
    <div className="pick-restaurant f f-direction-column">
      <div className="mt-4 modal__img">
        <img src="/static/images/modals/pin.png" alt="smile emoji" />
      </div>
      <div className="p-3 align-text-center f-jc-space-b f-ai-center pb-3 ft-center ">
        <h2>
          <Trans
            ns="modals"
            i18nKey="restaurant.title"
            components={[<span className=" ft-cap-first c-primary"></span>]}
          />
        </h2>
        <div className="mt-3">
          <span className="t-2">
            <Trans
              ns="modals"
              i18nKey="restaurant.subtitle"
              components={[<span className=" ft-cap-first c-primary"></span>]}
              values={{ restaurant: configuration?.dn }}
            />
          </span>
        </div>
      </div>

      <div className="pick-restaurant__header f f-jc-space-b f-ai-center pb-3">
        <div className="pick-restaurant__input f f-ai-center">
          <Icon icon={Icons.search} />
          <TextInput
            autofocus={true}
            placeholder={t("restaurant.placeholder")}
            label="search-restaurant"
            value={search}
            setValue={setSearch}
            raw
          />
        </div>
      </div>

      {search.length < MIN_SEARCH_LENGTH ? (
        <></>
      ) : filteredEstablishments.length > 0 ? (
        filteredEstablishments.map((establishment, index) => (
          <Restaurant
            key={establishment.cid}
            name={establishment.n}
            address={`${establishment.sa}, ${establishment.c}, ${establishment.pc}`}
            index={index}
            setSelectedRestaurantIndex={onFilteredEstablishmentClick}
            hideModal={hideModal}
            className="pick-restaurant__restaurant p-3"
          />
        ))
      ) : (
        <p className="p-2 ft-center">
          <Trans ns="modals" i18nKey="restaurant.noResults" />
        </p>
      )}
    </div>
  );
};

export default ModalConfigPickRestaurant;
