import { MouseEventHandler } from "react";
import { getTwoDigitsNumber, getPrizeExpirationDate } from "utils";
import dayjs from "dayjs";
import { GameRewardInfo } from "redux/game/game.interface";

export const handleCTAClick = (
  showModalCollectFeedback: () => void
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    showModalCollectFeedback();
  };

  return handler;
};

export const formatRemainingTime = (remainingTime: number) => {
  let minutes = ~~(remainingTime / 60);
  let seconds = remainingTime % 60;

  return `${seconds < 0 ? "-" : ""}${getTwoDigitsNumber(
    minutes < 0 ? Math.abs(minutes) : minutes
  )}:${getTwoDigitsNumber(seconds < 0 ? Math.abs(seconds) : seconds)}`;
};

export const isRewardExpired = (reward: GameRewardInfo | null) => {
  if (reward === null) {
    return true;
  }

  const expiration = getPrizeExpirationDate(
    reward?.pc.duration,
    reward?.pc.period,
    reward?.wa
  );
  if (expiration && reward) {
    return dayjs().isAfter(dayjs(expiration));
  }
  return true;
};

export const canClaimTheReward = (
  wonDate: string | undefined,
  instantAvailability: number | undefined
) => {
  const wonDateToZero = dayjs(wonDate).hour(0).minute(0).second(0);

  if (!wonDate) return false;
  if (
    instantAvailability === 1 ||
    dayjs(dayjs()).diff(wonDateToZero, "day") >= 1
  ) {
    return true;
  }
  return false;
};

export const getClaimRewardAvailableDate = (wonDate: string | undefined) => {
  return dayjs(wonDate)
    .hour(0)
    .minute(0)
    .second(0)
    .add(1, "day")
    .format("DD/MM/YYYY");
};
