import { MouseEventHandler } from "react";
import { NavigateFunction } from "react-router-dom";
import { Routes, ROUTES } from "utils/constants/router";
import { IProps } from "./modalConfigStepGoogle.types";
import { track } from "utils";
import { MyliEventType } from "utils/types/event";
import { IAPIObjective } from "redux/configuration/configuration.interface";

export const handleCTAClick = (
  hideModal: IProps["hideModal"],
  navigate: NavigateFunction,
  marketAction: string | undefined
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    hideModal();

    track(MyliEventType.MARKET_ACTION, {
      objective: IAPIObjective.REVIEW_GOOGLE,
    });

    if (marketAction) {
      window.open(marketAction, "_blank");
    }
    setTimeout(() => {
      navigate(`../${ROUTES.get(Routes.game).path}`);
    }, 400);
  };

  return handler;
};
