import { FC, useContext, useState } from "react";
import { StepNavigationContext } from "components/StepNavigation";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleNextStepClick } from "./core/modalClaimPrizeStepName.helpers";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import TextInput from "components/Inputs/TextInput";
import { Trans, useTranslation } from "react-i18next";
import { ModalClaimPrizeContext } from "components/Modals/ModalClaim";
import { IProps } from "./core/modalClaimPrizeStepName.types";
import { useDispatch } from "react-redux";

const ModalClaimStepPrizeName: FC<IProps> = ({ step = 2 }) => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const { t } = useTranslation("modals", { keyPrefix: "prize.name" });
  const prizeContext = useContext(ModalClaimPrizeContext);
  const { firstName, setFirstName } = { ...prizeContext };
  const dispatch = useDispatch();
  const [tempFirstName, setTempFirstName] = useState<string>(firstName || "");

  return stepNavigationContext ? (
    <StepNavigationStep className="modal__content" step={step}>
      <div className="modal__img">
        <img
          src="/static/images/modals/rockstar.png"
          alt="rockstar sign emoji"
        />
      </div>
      <h2 className="modal__title">{t("title")}</h2>
      <p>
        <Trans
          ns="modals"
          i18nKey="prize.name.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <TextInput
        value={tempFirstName}
        setValue={setTempFirstName}
        label={t("label")}
        placeholder={t("placeholder")}
        className="modal__input"
      />
      <Button
        onClick={handleNextStepClick(
          stepNavigationContext,
          setFirstName,
          tempFirstName,
          dispatch
        )}
        text={t("CTA")}
        icon={Icons.chevronRight}
        className="modal__btn mt-0"
        disabled={tempFirstName === ""}
      />
    </StepNavigationStep>
  ) : null;
};

export default ModalClaimStepPrizeName;
