import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

const ModalAlreadyParticipated: FC = () => {
  const { configuration } = useSelector((state: IConfigurationState) => state);
  const { t } = useTranslation("modals", { keyPrefix: "alreadyParticipated" });

  return (
    <div className="modal__content">
      <div className="modal__img">
        <img src="/static/images/modals/smile.png" alt="smile emoji" />
      </div>
      <h2 className="modal__title">{t("title")}</h2>
      <p>
        {t("subtitle", {
          maxAttemps: configuration?.s.mxAtp,
          period: t(`period${configuration?.s?.pAtp}`),
        })}
      </p>
      {/*    <Button
        className="modal__btn mb-3 f"
        text={t("CTA")}
        onClick={handleCTAClick(navigate)}
        icon={Icons.chevronRight}
      /> */}
    </div>
  );
};

export default ModalAlreadyParticipated;
