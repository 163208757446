import { useEffect, useState } from "react";
import { IHook, EmailErrors } from "./useEmail.types";
import { isEmailValid } from "utils";

export const useEmail: IHook = ({ email }) => {
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (email && isEmailValid(email)) {
      if (!isValid) {
        setIsValid(true);
      }
    } else if (isValid) {
      setIsValid(false);
    } else {
      setErrorMessage(EmailErrors.INVALID);
    }
  }, [email, isValid]);

  return {
    isValid,
    errorMessage,
  };
};
