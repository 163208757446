import { FC } from "react";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import Button from "components/Inputs/Button";
import { Trans, useTranslation } from "react-i18next";
import { searchMarketAction } from "pages/Home/core/home.helpers";

import {
  MyliPlatformId,
  IConfigurationState,
  IAPIObjective,
} from "redux/configuration/configuration.interface";
import { useSelector } from "react-redux";

import { IModalChild } from "utils/types/modalChild";
import { useMarketActionCTA } from "utils/hooks/useMarketActionCTA/useMarketActionCTA";
import { getMobileOperatingSystem } from "utils";
import Icon from "components/Icon";
import { Icons } from "utils/constants/icons";

interface IProps extends IModalChild {}

const ModalConfigStepTripAdvisor: FC<IProps> = ({ hideModal }) => {
  const { t } = useTranslation("modals", { keyPrefix: "tripAdvisor" });
  const { configuration } = useSelector((state: IConfigurationState) => state);

  const marketAction = searchMarketAction(
    MyliPlatformId.TRIPADVISOR,
    configuration?.w
  );

  const handleCTAClick = useMarketActionCTA(
    hideModal,
    marketAction?.u,
    IAPIObjective.VISIT_TRIPADVISOR
  );
  const os = getMobileOperatingSystem();
  return (
    <StepNavigationStep className="modal__content" step={1}>
      <div className="modal__img">
        <img
          src="/static/images/modals/tripadvisor.png"
          alt="tripAdvisor icon"
        />
      </div>

      <div className="modal-line-step">
        <div className="badge mr-3">1</div>
        <h2 className="ft-left">
          <Trans
            ns="modals"
            i18nKey="tripAdvisor.firstStep"
            values={{ restaurant: configuration?.name }}
            components={[<span className="c-trip-advisor"></span>]}
          />
        </h2>
      </div>

      <div className="modal-line-step">
        <div className="badge mr-3">2</div>
        <h2 className="ft-left">
          <Trans
            ns="modals"
            i18nKey={`tripAdvisor.secondStep.${os}`}
            values={{ restaurant: configuration?.name }}
            components={[<span className="c-trip-advisor"></span>]}
          />
          {os === "iOS" && <Icon icon={Icons.tab} className="ml-2" />}
        </h2>
      </div>

      <div className="modal-line-step">
        <div className="badge mr-3">3</div>
        <h2 className="ft-left">
          <Trans
            ns="modals"
            i18nKey={`tripAdvisor.thirdStep`}
            values={{ restaurant: configuration?.name }}
            components={[<span className="c-trip-advisor"></span>]}
          />
        </h2>
      </div>

      <Button
        text={t("CTA")}
        onClick={handleCTAClick}
        appearance="tripAdvisor"
        className="modal__btn"
      />
    </StepNavigationStep>
  );
};

export default ModalConfigStepTripAdvisor;
