import { FC } from "react";
import { getIdFromLabel } from "utils";
import { handleChange } from "./core/textInput.helpers";
import { IProps } from "./core/textInput.types";

const TextInput: FC<IProps> = ({
  placeholder,
  value,
  setValue,
  className,
  type = "text",
  label,
  required = false,
  autofocus,
  raw = false,
}) => {
  const id = getIdFromLabel(label);

  return (
    <div className={`input ${className || ""} ${raw ? "input--raw" : ""}`}>
      <label htmlFor={id} className="input__label">
        {label}
      </label>
      <input
        required={required}
        value={value}
        onChange={handleChange(setValue)}
        placeholder={placeholder}
        className="input__input"
        type={type}
        id={id}
        autoFocus={autofocus}
      />
    </div>
  );
};

export default TextInput;
