import { FC } from "react";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import Button from "components/Inputs/Button";
import { Trans, useTranslation } from "react-i18next";
import { searchMarketAction } from "pages/Home/core/home.helpers";
import {
  MyliPlatformId,
  IConfigurationState,
  IAPIObjective,
} from "redux/configuration/configuration.interface";
import { useSelector } from "react-redux";
import { IModalChild } from "utils/types/modalChild";
import { useMarketActionCTA } from "utils/hooks/useMarketActionCTA/useMarketActionCTA";
import { getMobileOperatingSystem } from "utils";

interface IProps extends IModalChild {}

const isIos = getMobileOperatingSystem() === "iOS";

const ModalConfigStepSurvey: FC<IProps> = ({ hideModal }) => {
  const { t } = useTranslation("modals", { keyPrefix: "survey" });
  const { configuration } = useSelector((state: IConfigurationState) => state);

  const marketAction = searchMarketAction(
    isIos ? MyliPlatformId.SURVEY : MyliPlatformId.SURVEY,
    configuration?.w
  );

  const handleCTAClick = useMarketActionCTA(
    hideModal,
    marketAction?.u,
    IAPIObjective.SURVEY
  );

  return (
    <StepNavigationStep className="modal__content" step={1}>
      <div className="modal__img modal__img__secondary p-0">
        <img src="/static/images/modals/survey.png" alt="survey icon" />
      </div>

      <h2 className="modal__title">
        <Trans
          ns="modals"
          i18nKey="survey.title"
          components={[<span className="c-primary"></span>]}
        />
      </h2>

      <p className="mb-3">
        <Trans
          ns="modals"
          i18nKey="survey.subtitle"
          values={{ restaurant: configuration?.name }}
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>

      <Button
        text={t("CTA")}
        onClick={handleCTAClick}
        appearance="primary"
        className="modal__btn"
      />
    </StepNavigationStep>
  );
};

export default ModalConfigStepSurvey;
