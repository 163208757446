import { MouseEventHandler, RefObject, TouchEventHandler } from "react";
import { IProps } from "./custom-range.types";
import { GRADES } from "./customeRange.constants";

export const handleTouchMove = (
  reference: RefObject<HTMLDivElement>,
  setSelectedGrade: IProps["setValue"]
): TouchEventHandler => {
  const handler: TouchEventHandler = (e) => {
    if (!reference.current) {
      return;
    }
    const wrapperBoundingClientRect = reference.current.getBoundingClientRect();
    const touchPositionX = e.touches[0].clientX;
    const wrapperPositionX = wrapperBoundingClientRect.left;
    const wrapperWidth = reference.current.offsetWidth;

    const touchRelativePosition = touchPositionX - wrapperPositionX;

    for (let i = 1; i <= GRADES.length; i++) {
      if (touchRelativePosition < (wrapperWidth / GRADES.length) * i) {
        setSelectedGrade(i);
        break;
      }
    }
  };

  return handler;
};

export const handleClick = (
  setSelectedGrade: IProps["setValue"],
  grade: number
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    setSelectedGrade(grade);
  };

  return handler;
};
