import { ChangeEventHandler, FC } from "react";
import { IProps } from "./core/checkboxInput.types";
import { getIdFromLabel } from "utils";

const CheckboxInput: FC<IProps> = ({
  checked = false,
  setChecked,
  label,
  className,
}) => {
  const id = getIdFromLabel(label);

  const handleChange: ChangeEventHandler = () => {
    setChecked(!checked);
  };

  return (
    <div
      className={`checkbox-input f f-direction-row-reverse f-jc-end f-ai-start ${
        className || ""
      }`}
    >
      <label className="checkbox-input__label ft-left" htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className="checkbox-input__input mt-0 ml-0 mb-0 mr-2 p-1"
      />
    </div>
  );
};

export default CheckboxInput;
