import { Footer } from "components/Footer";
import { FC, useRef } from "react";
import { useLayout } from "./core/layout.hook";
import { IProps } from "./core/layout.types";

const Layout: FC<IProps> = ({
  children,
  className,
  hideFooter = false,
  isDesktop = false,
}) => {
  const reference = useRef<HTMLDivElement>(null);

  useLayout({ reference });

  return (
    <div
      ref={reference}
      className={`layout ${className || ""} f f-jc-space-b f-direction-column`}
    >
      <div
        className={
          isDesktop
            ? "layout__desktop f f-ai-center"
            : "layout__content f f-direction-column f-jc-center ft-center"
        }
      >
        {children}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default Layout;
