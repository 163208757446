import Button from "components/Inputs/Button";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Icons } from "utils/constants/icons";
import { handleCTAClick } from "./core/modalClaimCoupon.helpers";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

const ModalClaimStepCoupon: FC = () => {
  const { t } = useTranslation("modals", { keyPrefix: "coupon" });
  const { reward } = useSelector((state: IConfigurationState) => state.game);

  return (
    <div className="modal__content">
      <div className="modal__img">
        <img src={reward?.picture} alt="" />
      </div>
      <h2 className="modal__title">{t("title", { reward: reward?.name })}</h2>
      <p>
        <Trans
          ns="modals"
          i18nKey="coupon.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
          values={{ reward: reward?.name }}
        />
      </p>
      <Button
        text={t("CTA")}
        onClick={handleCTAClick(reward?.couponCode)}
        icon={Icons.chevronRight}
        className="modal__btn mb-4"
      />
      {/*  <p className="c-primary fw-600 mb-3">{t("giveToFriend")}</p> */}
    </div>
  );
};

export default ModalClaimStepCoupon;
