import { FC, MouseEventHandler, useContext, useState } from "react";
import { StepNavigationContext } from "components/StepNavigation";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import TextInput from "components/Inputs/TextInput";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import { Trans, useTranslation } from "react-i18next";
import { ModalClaimPrizeContext } from "components/Modals/ModalClaim";
import { IProps } from "./core/modalClaimPrizeStepMail.types";
import { useEmail } from "utils/hooks/useEmail";
import CheckboxInput from "components/Inputs/CheckboxInput";
import { useSelector, useDispatch } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { setUserData } from "redux/user/user.actions";
import { AppThunkDispatch } from "redux/configuration/configuration.types";
import { useParams } from "react-router";

const ModalClaimStepPrizeMail: FC<IProps> = ({ step = 3 }) => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const { t } = useTranslation("modals", { keyPrefix: "prize.email" });
  const { configuration, userData } = useSelector(
    (state: IConfigurationState) => state
  );
  const prizeContext = useContext(ModalClaimPrizeContext);
  const { email, firstName } = { ...prizeContext };
  const [optIn, setOptIn] = useState<boolean>(!!userData?.user?.optIn);
  const [tempEmail, setTempEmail] = useState<string>(email || "");
  const { isValid, errorMessage } = useEmail({ email: tempEmail });
  const dispatch: AppThunkDispatch = useDispatch();

  const { entityId } = useParams();

  const handleNextStepClick: MouseEventHandler = () => {
    const { nextStep } = stepNavigationContext;
    if (tempEmail) {
      dispatch(setUserData({ email: tempEmail, optIn, cpid: entityId }));
    }
    nextStep();
  };

  return stepNavigationContext ? (
    <StepNavigationStep className="modal__content" step={step}>
      <div className="modal__img">
        <img
          src="/static/images/modals/rockstar.png"
          alt="rockstar sign emoji"
        />
      </div>
      {firstName && (
        <h2 className="modal__title">{t("title", { name: firstName })}</h2>
      )}
      {!firstName && <h2 className="modal__title">{t("titleNoName")}</h2>}
      <p>
        <Trans
          ns="modals"
          i18nKey="prize.email.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <TextInput
        value={tempEmail}
        setValue={setTempEmail}
        placeholder={t("placeholder")}
        label={t("label")}
        type="email"
        className="modal__input"
      />
      {!isValid && tempEmail.length > 0 && errorMessage && (
        <div className="collect__error_container mb-4 c-error">
          <p>{t(errorMessage)}</p>
        </div>
      )}
      <CheckboxInput
        className="mb-3"
        checked={optIn}
        setChecked={setOptIn}
        label={t("contactAgreement", { restaurant: configuration?.dn })}
      />
      <Button
        text={t("CTA")}
        onClick={handleNextStepClick}
        icon={Icons.chevronRight}
        className="modal__btn mt-0"
        disabled={!isValid}
      />
    </StepNavigationStep>
  ) : null;
};

export default ModalClaimStepPrizeMail;
