import { FC } from "react";
import { IProps } from "components/Winner/core/winner.types";
import Avatar from "components/Avatar";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export type { IProps as IWinnnerProps } from "./core/winner.types";

const Winner: FC<IProps> = ({ isModal = false, winner, className }) => {
  const { t } = useTranslation(["home", "modals"]);
  return (
    <div
      className={`winner f ${!isModal ? "p-1 pr-3" : ""} ${className || ""}`}
    >
      <Avatar className="winner__avatar" name={winner.winnerDisplayName} />
      <div
        className={`winner__infos f f-direction-column f-jc-center ft-left ${
          !isModal ? "ml-2" : "ml-3"
        }`}
      >
        <p className="winner__prize fw-500 ft-cap-first">
          {isModal
            ? t("winners.winnerFirstTry", {
                ns: "modals",
                reward: winner.item,
                name: winner.winnerDisplayName,
              })
            : t("winnerPrize", {
                ns: "home",
                reward: winner.item,
                name: winner.winnerDisplayName,
              })}
        </p>
        <p className="winner__time">
          {dayjs(dayjs(winner?.winAt).format()).fromNow()}
        </p>
      </div>
    </div>
  );
};

export default Winner;
