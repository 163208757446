import { FC } from "react";
import { getInitials } from "utils";
import { IProps } from "./core/avatar.types";

const Avatar: FC<IProps> = ({ name, className }) => {
  return (
    <div className={`avatar bg-primary-light f f-center ${className || ""}`}>
      <p className="ft-up fs-x-small c-primary mb-0 fw-500">
        {getInitials(name)}
      </p>
    </div>
  );
};

export type { IProps as IAvatarProps } from "./core/avatar.types";

export default Avatar;
