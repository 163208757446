import { FC } from "react";
import { SwiperSlide } from "swiper/react";

const CarouselItem: FC = ({ children }) => {
  return <SwiperSlide>{children}</SwiperSlide>;
};

CarouselItem.displayName = "SwiperSlide";

export default CarouselItem;
