import Icon from "components/Icon";
import Winner from "components/Winner";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { Icons } from "utils/constants/icons";
import { handleCloseClick } from "./core/modalWinners.helpers";
import { IProps } from "./core/modalWinners.type";

const ModalWinners: FC<IProps> = ({ hideModal }) => {
  const { t } = useTranslation("modals", { keyPrefix: "winners" });
  const { winners, winnersCount } = useSelector(
    (state: IConfigurationState) => state
  );

  return (
    <>
      <div className="modal__header ft-center">
        <h2>{t("title")}</h2>
        <Icon
          className="modal__close"
          icon={Icons.chevronDownBlack}
          onClick={handleCloseClick(hideModal)}
        />
      </div>
      <div className="f f-direction-column ft-center">
        <p className="mt-4 mb-4">
          <Trans
            ns="modals"
            i18nKey="winners.subtitle"
            components={[<span className="c-primary fw-700"></span>]}
            values={{ count: winnersCount }}
          />
        </p>
        {winners.map((winner) => (
          <Winner className="mb-3" isModal winner={winner} />
        ))}
      </div>
    </>
  );
};

export default ModalWinners;
