import dayjs from "dayjs";
import { useEffect } from "react";
import { setRewardInfo } from "redux/game/game.actions";
import { IHook } from "./collect.types";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const useCollect: IHook = ({
  showModalCollectInstructions,
  activationDate,
  setRemainingTime,
  alreadyClaimed,
  setAlreadyClaimed,
  setActivationDate,
  rewardId,
  reward,
  dispatch,
  token,
  claimLimitTime,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (reward && reward.ra === null) {
      showModalCollectInstructions();
    }
  }, [reward, showModalCollectInstructions]);

  useEffect(() => {
    if (token) {
      Cookies.set("jwt", token);
    }
  }, [token]);

  useEffect(() => {
    if (!rewardId || !token) {
      navigate("/404");
      return;
    }
    if (token) {
      dispatch(setRewardInfo(rewardId, token));
    }
  }, [dispatch, rewardId, navigate, token]);

  useEffect(() => {
    if (!reward || !reward.ra) {
      return;
    }

    setActivationDate(dayjs(reward.ra));
  }, [reward, setActivationDate]);

  useEffect(() => {
    if (!activationDate) {
      return;
    }

    const interval = setInterval(() => {
      const remainingTime =
        claimLimitTime - dayjs().diff(activationDate, "seconds");
      if (remainingTime < 0 && alreadyClaimed === null) {
        setAlreadyClaimed(false);
      }
      setRemainingTime(remainingTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [
    activationDate,
    setRemainingTime,
    claimLimitTime,
    alreadyClaimed,
    setAlreadyClaimed,
  ]);
};
