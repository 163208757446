import { MouseEventHandler } from "react";

export const mouseEventHandler: MouseEventHandler = () => {
  console.log("clicked");
};

export const handleFormSubmit = (
  text: string,
  phone: string,
  message: string,
  select: string | undefined,
  checked: boolean
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    console.log({ text, phone, message, select, checked });
  };

  return handler;
};
