import { MouseEventHandler } from "react";

export const handleCTAClick = (code: string | undefined): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    const shareData: ShareData = {
      text: code,
    };
    if (code) {
      navigator.share(shareData);
    } else {
      console.error("no code");
    }
  };

  return handler;
};
