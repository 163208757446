import { FC, useContext, useState } from "react";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { IProps } from "./core/modalConfigStepNewsletterPhone.types";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import TextInput from "components/Inputs/TextInput";
import { Trans, useTranslation } from "react-i18next";
import { ModalConfigNewsleterContext } from "components/Modals/ModalConfig";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import { useModalConfigStepNewsletterPhone } from "./core/modalConfigStepNewsletterPhone.hook";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { StepNavigationContext } from "components/StepNavigation";
import { handleNextStepClick } from "./core/modalConfigStepNewsletterPhone.helpers";

const ModalConfigStepNewsletterPhone: FC<IProps> = ({ stepOrder }) => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const newsletterContext = useContext(ModalConfigNewsleterContext);
  const { configuration } = useSelector((state: IConfigurationState) => state);
  const locale = configuration?.c?.lg[0].slice(0, 2);
  const { phoneNumber, setPhoneNumber } = { ...newsletterContext };
  const { t } = useTranslation("modals", { keyPrefix: "newsletter.phone" });
  const [tempPhoneNumber, setTempPhoneNumber] = useState<string>(
    phoneNumber || ""
  );

  useModalConfigStepNewsletterPhone({ tempPhoneNumber, setPhoneNumber });
  return (
    <StepNavigationStep className="modal__content" step={stepOrder}>
      <div className="modal__img">
        <img
          className="modal__img__newsletter"
          src="/static/images/modals/newsletter.png"
          alt="newsletter icon"
        />
      </div>
      <h2 className="modal__title">{t("title")}</h2>
      <p>
        <Trans
          ns="modals"
          i18nKey="newsletter.phone.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <TextInput
        value={tempPhoneNumber}
        setValue={setTempPhoneNumber}
        placeholder={t("placeholder")}
        label={t("label")}
        className="modal__input"
        type="tel"
      />
      <Button
        text={t("CTA")}
        onClick={handleNextStepClick(
          stepNavigationContext,
          setPhoneNumber,
          tempPhoneNumber
        )}
        icon={Icons.chevronRight}
        className="modal__btn mt-0"
        disabled={
          tempPhoneNumber.length < 5 ||
          !isValidPhoneNumber(
            tempPhoneNumber,
            locale.toUpperCase() as CountryCode
          )
        }
      />
    </StepNavigationStep>
  );
};

export default ModalConfigStepNewsletterPhone;
