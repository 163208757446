import { FC, useContext } from "react";
import ModalClaim from "components/Modals/ModalClaim";
import Modal from "utils/hookedComponents/Modal";
import { GameContext } from "pages/Game";
import { Rewards } from "pages/Game/core/game.types";
import { useGamePrize } from "./core/gamePrize.hook";

const GamePrize: FC = () => {
  const gameContext = useContext(GameContext);
  const { isVisible, showModal, reference } = { ...gameContext };

  useGamePrize({ showModal });

  return isVisible ? (
    <Modal
      reference={reference}
      isVisible={isVisible}
      content={<ModalClaim reward={Rewards.prize} />}
    />
  ) : null;
};

export default GamePrize;
