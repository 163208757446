import { Company, User } from "./user.interface";

export enum ActionTypes {
  SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY",
  SET_NPS_SCORE = "SET_NPS_SCORE",
  SET_USER_DATA = "SET_USER_DATA",
  SET_SATISFACTION_INQUIRY = "SET_SATISFACTION_INQUIRY",
}

interface UserSetCompaniesAction {
  type: typeof ActionTypes.SET_SELECTED_COMPANY;
  payload: Company;
}

interface UserSetNPSAction {
  type: typeof ActionTypes.SET_NPS_SCORE;
  payload: number;
}

interface UserSetUserDataAction {
  type: typeof ActionTypes.SET_USER_DATA;
  payload: User;
}

interface UserSetSatisfactionInquery {
  type: typeof ActionTypes.SET_SATISFACTION_INQUIRY;
  payload: boolean;
}

export type UserActionTypes =
  | UserSetCompaniesAction
  | UserSetNPSAction
  | UserSetUserDataAction
  | UserSetSatisfactionInquery;
