import { useEffect } from "react";
import { IHook } from "./app.types";

export const useScript: IHook = ({ scriptUrl, onScriptLoaded }) => {
  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = scriptUrl;
    script.defer = true;
    script.onload = onScriptLoaded;
    head.appendChild(script);
  }, [scriptUrl, onScriptLoaded]);
};
