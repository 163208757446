import { MouseEventHandler } from "react";
import { IStepNavigationContext } from "components/StepNavigation";

export const handleNextStepClick = (
  stepNavigationContext: IStepNavigationContext
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    stepNavigationContext.nextStep();
  };

  return handler;
};
