import axios from "axios";
import { env } from "config/env";

// config
import axiosConfig from "config/axios";

const AxiosService = {
  mocks: axios.create(),
  api: axios.create(),
};

AxiosService.mocks.interceptors.request.use((requestConfig) => {
  const newRequestConfig = {
    ...requestConfig,
    ...axiosConfig,
    baseURL: env.baseUrl,
  };
  return newRequestConfig;
});

AxiosService.api.interceptors.request.use((requestConfig) => {
  const newRequestConfig = {
    ...requestConfig,
    ...axiosConfig,
    baseURL: env.apiBaseUrl,
    withCredentials: true,
  };
  return newRequestConfig;
});

export default AxiosService;
