import { Dispatch, RefObject, SetStateAction } from "react";
import { IProps } from "./stepNavigation.types";

export const nextStep = (
  activeStep: number,
  setActiveStep: Dispatch<SetStateAction<number>>,
  numberOfSteps: IProps["numberOfSteps"],
  reference: RefObject<HTMLDivElement>
) => {
  return () => {
    if (activeStep + 1 > numberOfSteps || !reference.current) {
      return;
    }

    const step = reference.current.querySelector(".step-navigation__step");

    if (!step) {
      return;
    }

    step.classList.replace(
      "step-navigation__step--appearing",
      "step-navigation__step--disappearing"
    );

    setTimeout(() => {
      setActiveStep(activeStep + 1);
    }, 400);
  };
};

export const previousStep = (
  activeStep: number,
  setActiveStep: Dispatch<SetStateAction<number>>,
  numberOfSteps: IProps["numberOfSteps"],
  reference: RefObject<HTMLDivElement>
) => {
  return () => {
    if (activeStep - 1 < numberOfSteps) {
      return;
    }

    setActiveStep(activeStep - 1);
  };
};
