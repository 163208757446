import Layout from "components/Layout";
import { FC } from "react";
import { Trans } from "react-i18next";

const NotFound: FC = () => {
  return (
    <Layout className="collect">
      <div>
        <img src="/static/images/modals/signal-stud.png" alt="signal stud" />
      </div>
      <div>
        <h2 className="modal__title mt-7 mb-0">
          <Trans
            ns="notFound"
            i18nKey="title"
            components={[<span className="c-primary"></span>]}
          />
        </h2>
        <p className="mt-3">
          <Trans
            ns="notFound"
            i18nKey="subtitle"
            component={[<span className="c-primary"></span>]}
          />
        </p>
      </div>
      <img
        className="home__doodle home__doodle--1"
        src="/static/images/doodles/doodle-1.png"
        alt="doodle"
      />
      <img
        className="home__doodle home__doodle--2"
        src="/static/images/doodles/doodle-2.png"
        alt="doodle"
      />
    </Layout>
  );
};

export default NotFound;
