import { StepNavigationContext } from "components/StepNavigation";
import { FC, useContext } from "react";
import { IProps } from "./core/stepNavigationStep.types";

const StepNavigationStep: FC<IProps> = ({ step, children, className }) => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const { activeStep } = { ...stepNavigationContext };

  return activeStep === step ? (
    <div
      className={`step-navigation__step step-navigation__step--appearing ${className}`}
    >
      {children}
    </div>
  ) : null;
};

export default StepNavigationStep;
