import Game from "pages/Game";
import Home from "pages/Home";
import { Marketings as HomeRoutes } from "pages/Home/core/home.types";
import { Rewards as GameRoutes } from "pages/Game/core/game.types";
import HomeGoogle from "pages/HomeGoogle";
import HomeGoogleB from "pages/HomeGoogleB";

import HomeInstagram from "pages/HomeInstagram";
import HomeNewsletter from "pages/HomeNewsletter";
import HomeNps from "pages/HomeNps";
import HomeReferral from "pages/HomeReferral";
import HomeTwitter from "pages/HomeTwitter";
import { IChildRoute, IRoute } from "utils/types/route";
import GamePrize from "pages/GamePrize";
import GameCoupon from "pages/GameCoupon";
import GameLoss from "pages/GameLoss";
import Collect from "pages/Collect";
import Ui from "pages/Ui";
import HomeTikTok from "pages/HomeTikTok";
import HomeSelectRestaurant from "pages/HomeSelectRestaurant";
import HomeTripAdvisor from "pages/HomeTripAdvisor";
import HomeDownloadApp from "pages/HomeDownloadApp";
import HomeSurvey from "pages/HomeSurvey";
import HomeFacebook from "pages/HomeFacebook";

export { Marketings as HomeRoutes } from "../../pages/Home/core/home.types";
export { Rewards as GameRoutes } from "../../pages/Game/core/game.types";

const HOME = "";

export const HOME_ROUTES = new Map<HomeRoutes, IChildRoute>();

HOME_ROUTES.set(HomeRoutes.instagram, {
  path: HomeRoutes.instagram,
  page: <HomeInstagram />,
});

HOME_ROUTES.set(HomeRoutes.selectRestaurant, {
  path: HomeRoutes.selectRestaurant,
  page: <HomeSelectRestaurant />,
});

HOME_ROUTES.set(HomeRoutes.google, {
  path: HomeRoutes.google,
  page: <HomeGoogle />,
});

HOME_ROUTES.set(HomeRoutes.googlePlace, {
  path: HomeRoutes.googlePlace,
  page: <HomeGoogleB />,
});

HOME_ROUTES.set(HomeRoutes.newsletter, {
  path: HomeRoutes.newsletter,
  page: <HomeNewsletter />,
});

HOME_ROUTES.set(HomeRoutes.nps, {
  path: HomeRoutes.nps,
  page: <HomeNps />,
});

HOME_ROUTES.set(HomeRoutes.referral, {
  path: HomeRoutes.referral,
  page: <HomeReferral />,
});

HOME_ROUTES.set(HomeRoutes.twitter, {
  path: HomeRoutes.twitter,
  page: <HomeTwitter />,
});

HOME_ROUTES.set(HomeRoutes.tiktok, {
  path: HomeRoutes.tiktok,
  page: <HomeTikTok />,
});

HOME_ROUTES.set(HomeRoutes.facebook, {
  path: HomeRoutes.facebook,
  page: <HomeFacebook />,
});

HOME_ROUTES.set(HomeRoutes.tripAdvisor, {
  path: HomeRoutes.tripAdvisor,
  page: <HomeTripAdvisor />,
});

HOME_ROUTES.set(HomeRoutes.downloadApp, {
  path: HomeRoutes.downloadApp,
  page: <HomeDownloadApp />,
});

HOME_ROUTES.set(HomeRoutes.survey, {
  path: HomeRoutes.survey,
  page: <HomeSurvey />,
});

const GAME = "game";

export const GAME_ROUTES = new Map<GameRoutes, IChildRoute>();

GAME_ROUTES.set(GameRoutes.prize, {
  path: GameRoutes.prize,
  page: <GamePrize />,
});

GAME_ROUTES.set(GameRoutes.coupon, {
  path: GameRoutes.coupon,
  page: <GameCoupon />,
});

GAME_ROUTES.set(GameRoutes.loss, {
  path: GameRoutes.loss,
  page: <GameLoss />,
});

const COLLECT = "collect";
const UI = "ui";

export enum Routes {
  home = "home",
  game = "game",
  collect = "collect",
  ui = "ui",
}

export const ROUTES = new Map<Routes, IRoute>();

ROUTES.set(Routes.home, {
  path: HOME,
  page: <Home />,
  nestedRoutes: HOME_ROUTES,
});

ROUTES.set(Routes.game, {
  path: GAME,
  page: <Game />,
  nestedRoutes: GAME_ROUTES,
});

ROUTES.set(Routes.collect, {
  path: COLLECT,
  page: <Collect />,
});

ROUTES.set(Routes.ui, {
  path: UI,
  page: <Ui />,
});
