import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IHook } from "./useNPS.types";
import { setNPS } from "redux/user/user.actions";
import { AppThunkDispatch } from "redux/configuration/configuration.types";

export const useNPS: IHook = ({ score, entityId, isSent }) => {
  const dispatch: AppThunkDispatch = useDispatch();

  useEffect(() => {
    if (isSent && score !== -1 && entityId) {
      dispatch(setNPS(score, entityId));
    }
  }, [score, entityId, isSent, dispatch]);
};
