import { FC, useState, cloneElement } from "react";
import { IPrizeContext, IProps } from "./core/modalClaim.types";
import StepNavigation from "components/StepNavigation";
import ModalClaimLoss from "../ModalClaimLoss";
import ModalClaimCoupon from "../ModalClaimCoupon";
import ModalClaimPrizeStepConfirm from "../ModalsClaimPrizeSteps/ModalClaimPrizeStepConfirm";
import ModalClaimPrizeStepName from "../ModalsClaimPrizeSteps/ModalClaimPrizeStepName";
import ModalClaimPrizeStepMail from "../ModalsClaimPrizeSteps/ModalClaimPrizeStepMail";
import ModalClaimPrizeStepPhone from "../ModalsClaimPrizeSteps/ModalClaimPrizeStepPhone";
import ModalClaimPrizeStepReveal from "../ModalsClaimPrizeSteps/ModalClaimPrizeStepReveal";
import { Rewards } from "pages/Game/core/game.types";
import { PrizeContext } from "./core/modalClaim.context";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

export { PrizeContext as ModalClaimPrizeContext } from "./core/modalClaim.context";

const ModalClaim: FC<IProps> = ({ reward }) => {
  const { userData, game, configuration } = useSelector(
    (state: IConfigurationState) => state
  );
  const maxAttempts = configuration?.s?.mxAtp || 3;
  const storeUser = userData.user;

  const [firstName, setFirstName] = useState<string>(storeUser.firstName || "");
  const [email, setEmail] = useState<string>(storeUser.email || "");

  const [phoneNumber, setPhoneNumber] = useState<string>(
    storeUser.phoneNumber || ""
  );

  const prizeContext: IPrizeContext = {
    firstName,
    setFirstName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
  };

  const rewardsSteps = [
    { id: 1, component: <ModalClaimPrizeStepName key={1} /> },
    { id: 2, component: <ModalClaimPrizeStepMail key={2} /> },
    { id: 3, component: <ModalClaimPrizeStepPhone key={3} /> },
  ];

  const stepConfig = configuration?.s?.dh || [2];
  //Todo: api gives it to us
  const stepConfigFallBackMail =
    stepConfig.indexOf(2) > -1 ? stepConfig : [...stepConfig, 2];
  const modalClaimSteps = stepConfigFallBackMail.filter((step) => step !== 0);

  return (
    <>
      {reward === Rewards.prize && (
        <PrizeContext.Provider value={prizeContext}>
          <StepNavigation numberOfSteps={(modalClaimSteps.length || 0) + 2}>
            <ModalClaimPrizeStepReveal />
            {rewardsSteps
              .filter((step) => modalClaimSteps.includes(step.id))
              .map((matchingStep, index) =>
                cloneElement(matchingStep.component, {
                  step: 1 + index + 1,
                })
              )}
            <ModalClaimPrizeStepConfirm
              step={(modalClaimSteps.length || 0) + 2}
            />
          </StepNavigation>
        </PrizeContext.Provider>
      )}
      {reward === Rewards.loss && (
        <ModalClaimLoss
          canPlayAgain={game.canPlayAgain?.c || false}
          numberOfTries={game.canPlayAgain?.a || 0}
          maxAttemps={maxAttempts}
          isFreeWin={configuration?.s.isFW || false}
        />
      )}
      {reward === Rewards.coupon && <ModalClaimCoupon />}
    </>
  );
};

export default ModalClaim;
