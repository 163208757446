import { FC, useContext } from "react";
import { StepNavigationContext } from "components/StepNavigation";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleSurveyClick } from "./core/modalCollectFeedbackStepSurvey.helpers";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

const ModalCollectFeedbackStepSurvey: FC = () => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const rewardId = searchParams.get("rewardId");

  const { t } = useTranslation("modals", {
    keyPrefix: "collect.feedbackSurvey",
  });

  return stepNavigationContext ? (
    <StepNavigationStep className="modal__content" step={1}>
      <h2 className="modal__title mb-8 mt-4">{t("title")}</h2>
      <div className="f f-center mb-6">
        <button
          className="modal__btn modal__btn--feedback mr-8 f f-center"
          onClick={handleSurveyClick(
            true,
            stepNavigationContext,
            dispatch,
            rewardId
          )}
        >
          &#128077;
        </button>
        <button
          className="modal__btn modal__btn--feedback f f-center"
          onClick={handleSurveyClick(
            false,
            stepNavigationContext,
            dispatch,
            rewardId
          )}
        >
          &#128078;
        </button>
      </div>
    </StepNavigationStep>
  ) : null;
};

export default ModalCollectFeedbackStepSurvey;
