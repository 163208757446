import { useRef, useState } from "react";
import { IHook } from "./modal.types";

export const useModal: IHook = () => {
  const reference = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const showModal = () => {
    setIsVisible(true);
  };

  const hideModal = () => {
    if (!reference.current) {
      return;
    }

    reference.current.classList.replace(
      "modal--appearing",
      "modal--disappearing"
    );

    setTimeout(() => {
      setIsVisible(false);
    }, 400);
  };

  return [isVisible, showModal, hideModal, reference];
};
