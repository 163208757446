import { FC, useContext } from "react";
import { StepNavigationContext } from "components/StepNavigation";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleNextStepClick } from "./core/modalClaimPrizeStepReveal.helpers";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { getPrizeExpirationDate } from "utils";
import { useCurrency } from "utils/hooks/useCurrency";

const ModalClaimStepPrizeReveal: FC = () => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const { configuration, game } = useSelector(
    (state: IConfigurationState) => state
  );
  const { reward } = game;
  const { t } = useTranslation("modals", { keyPrefix: "prize.reveal" });

  const { currency } = useCurrency({
    locale: configuration?.c?.lg[0],
    country: configuration?.c?.cy,
    amount: reward?.pc.minimumAmount,
  });

  return stepNavigationContext ? (
    <StepNavigationStep className="modal__content bg-sparkles" step={1}>
      <div
        className="modal__img"
        style={{
          backgroundImage: `url("${
            reward?.picture || "/static/images/spin-game/gift.png"
          }")`,
        }}
      />
      <h2 className="modal__title">
        <Trans
          ns="modals"
          i18nKey="prize.reveal.title"
          components={[<p className=" ft-cap-first c-primary"></p>]}
          values={{ reward: reward?.name }}
        />
      </h2>
      <p className="mb-3">
        <Trans
          ns="modals"
          i18nKey="prize.reveal.subtitle"
          values={{
            reward: reward?.name,
            restaurant: configuration?.name,
            minimumAmount: currency,
          }}
          components={[<span className="c-primary fw-700"></span>]}
        />
        {reward?.pc.minimumAmount !== 0 ? (
          <span>
            <Trans
              ns="modals"
              i18nKey="prize.reveal.minimumAmount"
              values={{
                reward: reward?.name,
                restaurant: configuration?.name,
                minimumAmount: currency,
              }}
              components={[<span className="c-primary fw-700"></span>]}
            />
          </span>
        ) : (
          <span>
            <Trans
              ns="modals"
              i18nKey="prize.reveal.noMinimumAmout"
              values={{
                reward: reward?.name,
                restaurant: configuration?.name,
                minimumAmount: currency,
              }}
              components={[<span className="c-primary fw-700"></span>]}
            />
          </span>
        )}
        <span>
          <Trans
            ns="modals"
            i18nKey="prize.reveal.location"
            values={{
              brandname: configuration?.dn,
              delay: reward?.ia ? "" : t("delay"),
            }}
            components={[<span className="c-primary fw-700"></span>]}
          />
        </span>
      </p>
      {reward?.pc.duration !== 0 && (
        <p className="mb-3">
          <Trans
            ns="modals"
            i18nKey="prize.reveal.prizeDuration"
            values={{
              date: getPrizeExpirationDate(
                reward?.pc.duration,
                reward?.pc.period,
                undefined
              ),
            }}
            components={[<span className="c-primary fw-700"></span>]}
          />
        </p>
      )}
      <p>{t("subtitle2")}</p>
      <Button
        className="modal__btn mb-4"
        onClick={handleNextStepClick(stepNavigationContext)}
        text={t("CTA")}
        icon={Icons.chevronRight}
      />
      {/*       <p className="c-primary fw-600">L'offrir à un ami</p>
       */}{" "}
    </StepNavigationStep>
  ) : null;
};

export default ModalClaimStepPrizeReveal;
