import { FC } from "react";
import { createPortal } from "react-dom";
import { IProps } from "./core/modal.types";
import { Footer } from "components/Footer";

const Modal: FC<IProps> = ({
  content,
  isVisible,
  reference,
  position = "bottom",
  className,
}) => {
  const modal = (
    <div
      ref={reference}
      className={`modal modal--appearing ${
        position === "center" ? "modal--center" : ""
      } ${className || ""}`}
    >
      <div className="modal__bg"></div>
      <div className="modal__wrapper">
        {content}
        <Footer />
      </div>
    </div>
  );

  return isVisible ? createPortal(modal, document.body) : null;
};

export default Modal;
