import { MouseEventHandler, Dispatch } from "react";
import { IStepNavigationContext } from "components/StepNavigation";
import { IPrizeContext } from "components/Modals/ModalClaim/core/modalClaim.types";
import { setUserData } from "redux/user/user.actions";

export const handleNextStepClick = (
  stepNavigationContext: IStepNavigationContext,
  setFirstName: IPrizeContext["setFirstName"] | undefined,
  tempFirstName: string,
  dispatch: Dispatch<any>
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    setFirstName(tempFirstName);
    dispatch(setUserData({ firstName: tempFirstName }));
    stepNavigationContext.nextStep();
  };

  return handler;
};
