import { MouseEventHandler } from "react";
import { NavigateFunction } from "react-router-dom";
import { HomeRoutes, HOME_ROUTES } from "utils/constants/router";
import { Marketings } from "./home.types";
import {
  MyliPlatformId,
  MarketAction,
  IAPIEstablishment,
  IAPIObjective,
} from "redux/configuration/configuration.interface";

export const searchMarketAction = (
  pid: MyliPlatformId,
  marketActions: MarketAction[]
) => {
  return (
    marketActions?.find(
      (marketAction: MarketAction) => marketAction.pid === pid
    ) || null
  );
};

export const handleCTAClick = (
  navigate: NavigateFunction,
  marketing: IAPIObjective,
  ets?: IAPIEstablishment[]
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    let path = "";

    if (ets) {
      path = HOME_ROUTES.get(HomeRoutes.selectRestaurant).path;
      navigate(path);
      return;
    }

    switch (marketing) {
      case IAPIObjective.SELECT_RESTAURANT:
        path = HOME_ROUTES.get(HomeRoutes.selectRestaurant).path;
        break;
      case IAPIObjective.REVIEW_GOOGLE:
        path = HOME_ROUTES.get(HomeRoutes.google).path;
        break;
      case IAPIObjective.FOLLOW_INSTAGRAM:
        path = HOME_ROUTES.get(HomeRoutes.instagram).path;
        break;
      case IAPIObjective.NEWSLETTER:
        path = HOME_ROUTES.get(HomeRoutes.newsletter).path;
        break;
      case IAPIObjective.NPS:
        path = HOME_ROUTES.get(Marketings.nps).path;
        break;
      case IAPIObjective.REFER_FRIEND:
        path = HOME_ROUTES.get(Marketings.referral).path;
        break;
      case IAPIObjective.FOLLOW_TWITTER:
        path = HOME_ROUTES.get(Marketings.twitter).path;
        break;
      case IAPIObjective.GOOGLE_B:
        path = HOME_ROUTES.get(Marketings.googlePlace).path;
        break;
      case IAPIObjective.FOLLOW_TIKTOK:
        path = HOME_ROUTES.get(HomeRoutes.tiktok).path;
        break;
      case IAPIObjective.FOLLOW_FACEBOOK:
        path = HOME_ROUTES.get(HomeRoutes.facebook).path;
        break;
      case IAPIObjective.VISIT_TRIPADVISOR:
        path = HOME_ROUTES.get(HomeRoutes.tripAdvisor).path;
        break;
      case IAPIObjective.DOWNLOAD_APP:
        path = HOME_ROUTES.get(HomeRoutes.downloadApp).path;
        break;
      case IAPIObjective.SURVEY:
        path = HOME_ROUTES.get(HomeRoutes.survey).path;
        break;
    }
    if (path) {
      navigate(path);
    }
  };

  return handler;
};
