import { ActionTypes } from "./session.types";
import { IDispatch } from "redux/store";
import Cookies from "js-cookie";
import axios from "services/axios.service";
import { endpoints } from "config/endpoints";
import { getFingerprintId } from "utils/misc/fingerprint";
import { AntiFraudType } from "utils/types/session";

const getNewSession = async (antiFraudType: AntiFraudType, lang: string) => {
  const params = { lang };
  if (antiFraudType) {
    const fingerprintId = await getFingerprintId(antiFraudType);
    params["fId"] = fingerprintId;
    params["fType"] = antiFraudType;
  }
  await axios.api.get(`${endpoints.api.user.newSession}?`, {
    params,
  });
};

const checkCurrentSession = async (antiFraudType: AntiFraudType) => {
  // console.log("checkCurrentSession - Called");
  await axios.api.get(`${endpoints.api.user.regenerateToken}`);
  const token = Cookies.get("jwt");
  if (antiFraudType) {
    const payloadDecode = atob(token.split(".")[1]);
    const currentUser = JSON.parse(payloadDecode);

    if (!currentUser["f"]) {
      const fingerprint = await getFingerprintId(antiFraudType);
      await axios.api.put(endpoints.api.user.setData, {
        fingerprintId: fingerprint,
        fingerprintType: antiFraudType,
      });
    }
  }
};

export const setSession =
  (lang: string, antiFraudType: AntiFraudType) =>
  async (dispatch: IDispatch) => {
    console.log("setSession - Called");
    try {
      let token = Cookies.get("jwt");
      console.log("setSession - Token", token);
      if (!token) {
        await getNewSession(antiFraudType, lang);
      } else {
        try {
          await checkCurrentSession(antiFraudType);
        } catch (error) {
          console.log(
            "setSession - Error while executing checkCurrentSession",
            error
          );
          await getNewSession(antiFraudType, lang);
        }
      }

      dispatch({
        type: ActionTypes.SET_SESSION,
        payload: Cookies.get("jwt"),
      });
    } catch (err) {
      console.log("setSession - Error while setting a session", err);
    }
  };
