function searchNormalizedString(search: string, str: string): boolean {
  if (!search || !str) {
    return false;
  }

  const normalizedSearch = search
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  const normalizedStr = str
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");

  return normalizedStr.includes(normalizedSearch);
}

export default searchNormalizedString;
