import { MouseEventHandler, Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { Routes, ROUTES } from "utils/constants/router";
import { IProps } from "./modalConfigStepNewsletterContact.types";
import { setUserData } from "redux/user/user.actions";

export const handleCTAClick = (
  hideModal: IProps["hideModal"],
  navigate: NavigateFunction,
  optIn: boolean,
  dispatch: Dispatch<any>
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    hideModal();
    dispatch(setUserData({ optIn }));
    setTimeout(() => {
      navigate(`../${ROUTES.get(Routes.game).path}`);
    }, 400);
  };
  return handler;
};
