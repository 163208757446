import { ActionTypes } from "./configuration.types";
import { IDispatch } from "redux/store";
import axios from "services/axios.service";

// config
import { endpoints } from "config/endpoints";

export const fetchConfiguration =
  (cid: string) => async (dispatch: IDispatch) => {
    dispatch({
      type: ActionTypes.SET_CONFIGURATION,
    });

    try {
      let { data } = await axios.api.get(
        `${endpoints.api.configuration as any}`,
        {
          params: {
            cid,
          },
        }
      );

      dispatch({
        type: ActionTypes.SET_WINNERS_SUCCESS,
        payload:
          data?.f?.lw?.map((winner: any) => ({
            item: winner.rn,
            winnerDisplayName: winner.fn,
            winAt: winner.wa,
            tryCount: winner.a,
          })) || [],
      });

      dispatch({
        type: ActionTypes.SET_WINNERS_COUNT,
        payload: data?.f?.globalCount || 0,
      });

      dispatch({
        type: ActionTypes.SET_CONFIGURATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_CONFIGURATION_ERROR,
      });
    }
  };

export const setIsComingFromHome = () => async (dispatch: IDispatch) => {
  dispatch({
    type: ActionTypes.SET_IS_COMING_FROM_HOME,
  });
};
