import Icon from "components/Icon";
import { FC, useState } from "react";
import { Icons } from "utils/constants/icons";
import { handleTitleClick } from "./core/expandPanel.helpers";
import { IProps } from "./core/expandPanel.types";

const ExpandPanel: FC<IProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="expand-panel">
      <div
        className="expand-panel__title f f-jc-space-b f-ai-center"
        onClick={handleTitleClick(isOpen, setIsOpen)}
      >
        <p className="fw-600 fs-small mb-0">{title}</p>
        <Icon icon={Icons.chevronDownBlack} />
      </div>
      {isOpen && <p className="expand-panel__content fs-small">{content}</p>}
    </div>
  );
};

export default ExpandPanel;
