export enum ActionTypes {
  SET_SESSION = "SET_SESSION",
}

interface SetSessionAction {
  type: typeof ActionTypes.SET_SESSION;
  payload: string;
}

export type SessionActionsTypes = SetSessionAction;
