import { FC } from "react";
import { useNavigate } from "react-router-dom";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleCTAClick } from "./core/modalConfigStepReferral.helpers";
import { IProps } from "./core/modalConfigStepReferral.types";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

const ModalConfigStepReferral: FC<IProps> = ({ hideModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("modals", { keyPrefix: "referral" });
  const { configuration } = useSelector((state: IConfigurationState) => state);

  return (
    <StepNavigationStep className="modal__content" step={1}>
      <div className="modal__img">
        <img src="/static/images/modals/hand-shake.png" alt="handshake emoji" />
      </div>
      <h2 className="modal__title">
        <Trans
          ns="modals"
          i18nKey="referral.title"
          components={[<span className="c-primary"></span>]}
        />
      </h2>
      <p>{t("subtitle", { restaurant: configuration?.name || "" })}</p>
      <Button
        onClick={handleCTAClick(hideModal, navigate)}
        text={t("CTA")}
        icon={Icons.chevronRight}
        className="modal__btn"
      />
    </StepNavigationStep>
  );
};

export default ModalConfigStepReferral;
