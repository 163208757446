import { FC } from "react";
import StepNavigation from "components/StepNavigation";
import ModalCollectFeedbackStepConfirm from "../ModalsCollectFeedbackSteps/ModalCollectFeedbackStepConfirm";
import ModalCollectFeedbackStepSurvey from "../ModalsCollectFeedbackSteps/ModalCollectFeedbackStepSurvey";

const ModalCollectFeedback: FC = () => {
  return (
    <StepNavigation hideSteps numberOfSteps={2}>
      <ModalCollectFeedbackStepSurvey />
      <ModalCollectFeedbackStepConfirm />
    </StepNavigation>
  );
};

export default ModalCollectFeedback;
