const protocol = process.env?.REACT_APP_HTTPS === "true" ? "https" : "http";
const getUrl = window.location;

export const env = {
  myliSiteUrl: process.env?.REACT_APP_MYLI_SITE_URL || "https://www.myli.io",
  baseUrl: `${protocol}://${getUrl.host}`,
  avatarsBaseUrl: "https://eu.ui-avatars.com",
  apiBaseUrl: process.env?.REACT_APP_API_BASEURL,
  devToken: process.env?.REACT_APP_DEV_TOKEN,
  sentryDSN: process.env?.REACT_APP_SENTRY_DSN,
  libBaseUrl: process.env?.REACT_APP_LIB_BASEURL,
  fingerprintJsApiKey: process.env?.REACT_APP_FINGERPRINTJS_API_KEY,
};
