import { MouseEventHandler } from "react";

export const handleCTAClick = (
  showModalCollectDisclaimer: () => void
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    showModalCollectDisclaimer();
  };

  return handler;
};
