import { FC, useContext } from "react";
import ModalConfig from "components/Modals/ModalConfig";
import Modal from "utils/hookedComponents/Modal";
import { HomeContext } from "pages/Home";
import { Marketings } from "pages/Home/core/home.types";
import { useHomeSelectRestaurant } from "./core/HomeSelectRestaurant.hook";

const HomeSelectRestaurant: FC = () => {
  const homeContext = useContext(HomeContext);
  const { isVisible, showModal, hideModal, reference } = { ...homeContext };

  useHomeSelectRestaurant({ showModal });

  return isVisible && hideModal ? (
    <Modal
      reference={reference}
      isVisible={isVisible}
      content={
        <ModalConfig
          marketing={Marketings.selectRestaurant}
          hideModal={hideModal}
        />
      }
    />
  ) : null;
};

export default HomeSelectRestaurant;
