import { MouseEventHandler } from "react";

export const handleClick = (
  onClick: MouseEventHandler,
  disabled: boolean,
  loading: boolean
): MouseEventHandler => {
  const handler: MouseEventHandler = (e) => {
    if (disabled || loading) {
      return;
    }

    setTimeout(() => {
      onClick(e);
    }, 100);
  };

  return handler;
};
