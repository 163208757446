import { FC } from "react";

import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import Button from "components/Inputs/Button";
import { Trans, useTranslation } from "react-i18next";
import { searchMarketAction } from "pages/Home/core/home.helpers";

import {
  MyliPlatformId,
  IConfigurationState,
  IAPIObjective,
} from "redux/configuration/configuration.interface";
import { useSelector } from "react-redux";

import { IModalChild } from "utils/types/modalChild";
import { useMarketActionCTA } from "utils/hooks/useMarketActionCTA/useMarketActionCTA";

interface IProps extends IModalChild {}

const ModalConfigStepTikTok: FC<IProps> = ({ hideModal }) => {
  const { t } = useTranslation("modals", { keyPrefix: "tiktok" });
  const { configuration } = useSelector((state: IConfigurationState) => state);

  const marketAction = searchMarketAction(
    MyliPlatformId.TIKTOK,
    configuration?.w
  );

  const handleCTAClick = useMarketActionCTA(
    hideModal,
    marketAction?.u,
    IAPIObjective.FOLLOW_TIKTOK
  );

  return (
    <StepNavigationStep className="modal__content" step={1}>
      <div className="modal__img">
        <img src="/static/images/modals/tiktok.png" alt="tiktok logo" />
      </div>
      <h2 className="modal__title">
        <Trans
          ns="modals"
          components={[<span className="c-tiktok-title"></span>]}
          i18nKey="tiktok.title"
        />
      </h2>
      <p>{t("subtitle")}</p>
      <Button
        text={t("CTA")}
        onClick={handleCTAClick}
        appearance="tiktok"
        className="modal__btn"
      />
    </StepNavigationStep>
  );
};

export default ModalConfigStepTikTok;
