import { FC } from "react";
import { IComposition, IProps } from "./core/carousel.types";
import { Swiper } from "swiper/react";
import "swiper/css";
import CarouselItem from "./composition/CarouselItem";

const Carousel: FC<IProps> & IComposition = ({ className, children }) => {
  return (
    <div className={`carousel ${className || ""}`}>
      <Swiper
        loop={true}
        spaceBetween={8}
        slidesPerView={"auto"}
        loopedSlides={3}
      >
        {children}
      </Swiper>
    </div>
  );
};

Carousel.Item = CarouselItem;

export default Carousel;
