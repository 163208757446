import Button from "components/Inputs/Button";
import { CollectContext } from "pages/Collect";
import { FC, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleCTAClick } from "./core/modalCollectDisclaimer.helpers";
import { IProps } from "./core/modalCollectDisclaimer.types";
import { IConfigurationState } from "redux/configuration/configuration.interface";

const ModalCollectDisclaimer: FC<IProps> = ({
  hideModal,
  hideModalCollectInstructions,
}) => {
  const { t } = useTranslation("modals", { keyPrefix: "collect.disclaimer" });
  const collectContext = useContext(CollectContext);
  const { rewardId, companyId } = { ...collectContext };
  const dispatch = useDispatch();
  const { reward } = useSelector((state: IConfigurationState) => state);
  const toWhom = reward?.tw;

  return (
    <div className="modal__content">
      <div className="modal__img">
        <img
          src="/static/images/modals/exclamation-mark.png"
          alt="exclamation mark emoji"
        />
      </div>
      <h2 className="modal__title">
        {t("title")}
        <span className="c-primary">{toWhom}</span>
      </h2>
      <p>
        <Trans
          ns="modals"
          i18nKey="collect.disclaimer.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <Button
        onClick={handleCTAClick(
          hideModal,
          hideModalCollectInstructions,
          dispatch,
          rewardId,
          companyId
        )}
        text={t("CTA")}
        className="modal__btn"
      />
    </div>
  );
};

export default ModalCollectDisclaimer;
