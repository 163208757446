// import ModalContact from "components/Modals/ModalContact";
// import ModalHelp from "components/Modals/ModalHelp";
// import ModalRules from "components/Modals/ModalRules";
// import Modal from "utils/hookedComponents/Modal";
// import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import { env } from "config/env";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

export const Footer: FC = () => {
  const { t } = useTranslation("footer");
  const { configuration } = useSelector((state: IConfigurationState) => state);

  const openLink = useCallback((linkToOpen: string | undefined) => {
    if (linkToOpen) {
      window.open(linkToOpen, "_blank");
    }
  }, []);

  const poweredByUrl = useMemo(() => {
    return `${env.myliSiteUrl}/contact/?utm_source=${encodeURIComponent(
      configuration?.dn || ""
    )}&utm_medium=contest`;
  }, [configuration?.dn]);

  // const [isModalContactVisible, , hideModalContact, modalContactReference] =
  //   useModal();

  // const [isModalHelpVisible, , hideModalHelp, modalHelpReference] = useModal();
  // const [isModalRulesVisible, , hideModalRules, modalRulesReference] =
  //   useModal();

  return (
    <>
      <div className="footer">
        <div className="footer-box">
          {configuration?.c.rpdf && (
            <div
              className="footer-link footer-link-underlined"
              onClick={() => {
                openLink(configuration.c.rpdf);
              }}
            >
              {t("rules")}
            </div>
          )}
        </div>

        <div className="footer-box footer-box-middle">
          <span className="footer-link">Powered by</span>
          <img
            className="footer-logo"
            src="/static/images/logos/logo-myli-mini.png"
            alt="logo myli mini"
            onClick={() => {
              openLink(poweredByUrl);
            }}
          />
        </div>
        <div className="footer-box">{/* aligner */}⠀</div>
        {/*<p
          onClick={handleFooterLinkClick(showModalHelp)}
          className="footer-link"
        >
          {t("help")}
        </p> */}
        {/*<p
          onClick={handleFooterLinkClick(showModalContact)}
          className="footer-link"
        >
          {t("contact")}
        </p> 
               <p onClick={handleLanguageClick} className="footer-link">
          {t("language")}
        </p>
        */}
      </div>
      {/* <Modal
        reference={modalContactReference}
        isVisible={isModalContactVisible}
        content={<ModalContact hideModal={hideModalContact} />}
      />
      <Modal
        reference={modalHelpReference}
        isVisible={isModalHelpVisible}
        content={<ModalHelp hideModal={hideModalHelp} />}
      />
      <Modal
        reference={modalRulesReference}
        isVisible={isModalRulesVisible}
        content={<ModalRules hideModal={hideModalRules} />}
      /> */}
    </>
  );
};
