export enum MyliEventType {
  CAMPAIGN_VISIT = "CAMPAIGN_VISIT",
  GAME_PLAY = "GAME_PLAY",
  MARKET_ACTION = "MARKET_ACTION",
  DATA_COLLECT_STEP = "DATA_COLLECT_STEP",
  GAME_RESTART = "GAME_RESTART",
  WRONG_IMAGES_LOSS = "WRONG_IMAGES_LOSS",
  ALERT_STEP = "ALERT_STEP",
}

export interface MyliEvent {
  campaignId?: string;
  userId?: string | null;
  eventType?: MyliEventType;
  hasWon?: boolean | null;
  objective?: number;
  activeStep?: number;
  numberOfSteps?: number;
  game?: string;
}
