import { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, ROUTES } from "utils/constants/router";
import { track } from "utils";
import { MyliEventType } from "utils/types/event";
import { IAPIObjective } from "redux/configuration/configuration.interface";

export const useMarketActionCTA = (
  hideModal: () => void,
  marketActionUrl?: string,
  marketActionType?: IAPIObjective
): MouseEventHandler => {
  const navigate = useNavigate();

  return () => {
    hideModal();

    if (marketActionType) {
      track(MyliEventType.MARKET_ACTION, {
        objective: marketActionType,
      });
    }

    if (marketActionUrl) {
      window.open(marketActionUrl, "_blank");
    }
    setTimeout(() => {
      navigate(`../${ROUTES.get(Routes.game).path}`);
    }, 400);
  };
};
