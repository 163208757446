import { FC, useContext } from "react";
import ModalConfig from "components/Modals/ModalConfig";
import Modal from "utils/hookedComponents/Modal";
import { useHomeReferral } from "./core/homeReferral.hook";
import { HomeContext } from "pages/Home";
import { Marketings } from "pages/Home/core/home.types";

const HomeReferral: FC = () => {
  const homeContext = useContext(HomeContext);
  const { isVisible, showModal, hideModal, reference } = { ...homeContext };

  useHomeReferral({ showModal });

  return isVisible && hideModal ? (
    <Modal
      reference={reference}
      isVisible={isVisible}
      content={
        <ModalConfig marketing={Marketings.referral} hideModal={hideModal} />
      }
    />
  ) : null;
};

export default HomeReferral;
