import { FC } from "react";
import { useNavigate } from "react-router-dom";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { IProps } from "./core/modalConfigStepTwitter.types";
import Button from "components/Inputs/Button";
import { Trans, useTranslation } from "react-i18next";
import { searchMarketAction } from "pages/Home/core/home.helpers";
import { handleCTAClick } from "../ModalConfigStepGoogle/core/modalConfigStepGoogle.helpers";
import {
  MyliPlatformId,
  IConfigurationState,
} from "redux/configuration/configuration.interface";
import { useSelector } from "react-redux";

const ModalConfigStepTwitter: FC<IProps> = ({ hideModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("modals", { keyPrefix: "twitter" });
  const { configuration } = useSelector((state: IConfigurationState) => state);

  const marketAction = searchMarketAction(
    MyliPlatformId.TWITTER,
    configuration?.w
  );

  return (
    <StepNavigationStep className="modal__content" step={1}>
      <div className="modal__img">
        <img src="/static/images/modals/thumb-up.png" alt="thumb up emoji" />
      </div>
      <h2 className="modal__title">
        <Trans
          ns="modals"
          components={[<span className="c-google"></span>]}
          i18nKey="twitter.title"
        />
      </h2>
      <p>{t("subtitle")}</p>
      <Button
        text={t("CTA")}
        onClick={handleCTAClick(hideModal, navigate, marketAction?.u)}
        appearance="google"
        className="modal__btn"
      />
    </StepNavigationStep>
  );
};

export default ModalConfigStepTwitter;
