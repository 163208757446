import { MouseEventHandler, Dispatch } from "react";
import { IStepNavigationContext } from "components/StepNavigation";
import { INewsletterContext } from "components/Modals/ModalConfig/core/modalConfig.types";
import { setUserData } from "redux/user/user.actions";

export const handleNextStepClick = (
  stepNavigationContext: IStepNavigationContext,
  setEmail: INewsletterContext["setEmail"] | undefined,
  tempEmail: string,
  companyId: string,
  dispatch: Dispatch<any>
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    setEmail(tempEmail);
    dispatch(setUserData({ email: tempEmail, cpid: companyId }));
    stepNavigationContext.nextStep();
  };

  return handler;
};
