import fingerprintJsPro, {
  Agent as ProAgent,
} from "@fingerprintjs/fingerprintjs-pro";

import fingerprintJfFree, {
  Agent as FreeAgent,
} from "@fingerprintjs/fingerprintjs";

import { env } from "config/env";
import { AntiFraudType } from "utils/types/session";

export const getFingerprintId = async (
  antiFraudType: AntiFraudType
): Promise<string> => {
  const isProVersion = antiFraudType === AntiFraudType.PRO;
  try {
    // Initialize an agent at application startup.
    let fingerprintJs: FreeAgent | ProAgent;
    if (isProVersion) {
      fingerprintJs = await fingerprintJsPro.load({
        apiKey: env.fingerprintJsApiKey,
        scriptUrlPattern: [
          "https://fp-game.myli.io/behavior/agent?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
          fingerprintJsPro.defaultScriptUrlPattern, // Fallback to default CDN in case of error
        ],
        endpoint: [
          "https://fp-game.myli.io/behavior/result?region=eu",
          fingerprintJsPro.defaultEndpoint, // Fallback to default endpoint in case of error
        ],
      });
    } else {
      fingerprintJs = await fingerprintJfFree.load();
    }

    // Get the visitor identifier when you need it.
    const result = await fingerprintJs.get();
    return result?.visitorId;
  } catch (error) {
    console.error("getFingerprintId - Unable to get a fingerprint id", error);
    if (isProVersion) {
      console.warn("getFingerprintId - Fallback to the free version");
      return getFingerprintId(AntiFraudType.FREE);
    }
  }
  return "";
};
