import { MouseEventHandler, Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import { ROUTES, Routes } from "utils/constants/router";
import { IProps } from "./modalConfigStepNps.types";
import { track } from "utils";
import { MyliEventType } from "utils/types/event";
import { IAPIObjective } from "redux/configuration/configuration.interface";

export const handleCTAClick = (
  hideModal: IProps["hideModal"],
  navigate: NavigateFunction,
  setIsSent: Dispatch<SetStateAction<boolean>>
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    hideModal();
    setIsSent(true);
    setTimeout(() => {
      track(MyliEventType.MARKET_ACTION, {
        objective: IAPIObjective.NPS,
      });

      navigate(`../${ROUTES.get(Routes.game).path}`);
    }, 400);
  };

  return handler;
};
