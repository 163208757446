import { MouseEventHandler } from "react";
import { IProps } from "./restaurant.types";

export const handleClick = (
  setSelectedRestaurantIndex: IProps["setSelectedRestaurantIndex"],
  index: IProps["index"],
  hideModal: IProps["hideModal"]
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    setSelectedRestaurantIndex(index);
    hideModal();
  };

  return handler;
};
