import { useEffect } from "react";
import { IHook } from "./layout.types";

export const useLayout: IHook = ({ reference }) => {
  useEffect(() => {
    if (!reference.current) {
      return;
    }

    reference.current.style.minHeight = "-webkit-fill-available";

    if (
      window.innerHeight === reference.current.getBoundingClientRect().height
    ) {
      return;
    }

    reference.current.style.minHeight = "100vh";
  }, [reference]);
};
