import { FC, useContext } from "react";
import ModalClaim from "components/Modals/ModalClaim";
import Modal from "utils/hookedComponents/Modal";
import { GameContext } from "pages/Game";
import { useGameCoupon } from "./core/gameCoupon.hook";
import { Rewards } from "pages/Game/core/game.types";

const GameCoupon: FC = () => {
  const gameContext = useContext(GameContext);
  const { isVisible, showModal, reference } = { ...gameContext };

  useGameCoupon({ showModal });

  return isVisible ? (
    <Modal
      reference={reference}
      isVisible={isVisible}
      content={<ModalClaim reward={Rewards.coupon} />}
    />
  ) : null;
};

export default GameCoupon;
