import { useEffect, useState } from "react";
import { getAllInfoByISO } from "iso-country-currency";

import { IHook } from "./useCurrency.types";

export const useCurrency: IHook = ({ locale, country, amount }) => {
  const [amountWithCurrency, setAmountWithCurrency] = useState<string>("");

  useEffect(() => {
    let newCountry = country || locale || "FR"; // fal!back in case not provided, try the "locale" string
    let newLocale = locale || country || "FR"; // fal!back in case not provided, try the "country" string
    if (newLocale && newCountry && amount) {
      newCountry = newCountry.toUpperCase() === "EN" ? "GB" : newCountry;
      let currency = "EUR";
      try {
        currency = getAllInfoByISO(newCountry)?.currency;
      } catch (err) {
        console.error("Error while getAllInfoByISO : ", err);
      }
      const currencyLocale = `${newLocale}-${newCountry}`;
      const newAmountWithCurrency = new Intl.NumberFormat(currencyLocale, {
        style: "currency",
        currency,
      }).format(amount);

      setAmountWithCurrency(newAmountWithCurrency);
    }
  }, [locale, country, amount]);

  return { currency: amountWithCurrency };
};
