import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleCTAClick } from "./core/modalConfigStepNps.helpers";
import { IProps } from "./core/modalConfigStepNps.types";
import { Icons } from "utils/constants/icons";
import Button from "components/Inputs/Button";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { CustomRange } from "components/CustomRange";
import { useNPS } from "utils/hooks/useNPS";

const ModalConfigStepNps: FC<IProps> = ({ hideModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("modals", { keyPrefix: "nps" });
  const { configuration } = useSelector((state: IConfigurationState) => state);
  const [grade, setGrade] = useState<number>(-1);
  const { entityId } = useParams();
  const [isSent, setIsSent] = useState(false);

  useNPS({ score: grade, entityId, isSent });

  return (
    <StepNavigationStep className="modal__content" step={1}>
      <div className="modal__img">
        <img src="/static/images/modals/heart.png" alt="heart emoji" />
      </div>
      <h2 className="mb-3">
        <Trans ns="modals" i18nKey="nps.title" />
      </h2>
      <p className="mb-3">{t("subtitle", { restaurant: configuration?.dn })}</p>
      <CustomRange value={grade} setValue={setGrade} />
      <Button
        onClick={handleCTAClick(hideModal, navigate, setIsSent)}
        text={t("CTA")}
        icon={Icons.chevronRight}
        className="modal__btn"
        disabled={grade === -1}
      />
    </StepNavigationStep>
  );
};

export default ModalConfigStepNps;
