import { useEffect } from "react";
import { IHook } from "./carouselAutoSwiping.types";

export const useCarouselAutoSwiping: IHook = ({ swiper, speed }) => {
  useEffect(() => {
    if (speed) {
      const interval = setInterval(() => {
        swiper.slideNext(speed);
      }, speed);
      return () => clearInterval(interval);
    }
  }, [swiper, speed]);
};
