import { FC } from "react";
import { Icons } from "utils/constants/icons";
import { IProps } from "./core/icon.types";

const Icon: FC<IProps> = ({ icon, className, onClick }) => {
  return (
    <img
      className={`icon ${icon === Icons.loading ? "icon--loading" : ""} ${
        className || ""
      }`}
      alt={`${icon} icon`}
      src={`/static/icons/${icon}.svg`}
      height={24}
      width={24}
      onClick={onClick}
    />
  );
};

export default Icon;
