import { FC, useContext, useEffect } from "react";
import ModalConfig from "components/Modals/ModalConfig";
import Modal from "utils/hookedComponents/Modal";
import { HomeContext } from "pages/Home";
import { Marketings } from "pages/Home/core/home.types";

const HomeFacebook: FC = () => {
  const homeContext = useContext(HomeContext);
  const { isVisible, showModal, hideModal, reference } = { ...homeContext };

  useEffect(() => {
    if (!showModal) {
      return;
    }

    showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVisible && hideModal ? (
    <Modal
      reference={reference}
      isVisible={isVisible}
      content={
        <ModalConfig marketing={Marketings.facebook} hideModal={hideModal} />
      }
    />
  ) : null;
};

export default HomeFacebook;
