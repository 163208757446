import { FC, cloneElement, useState } from "react";
import { Marketings } from "pages/Home/core/home.types";
import StepNavigation from "components/StepNavigation";
import ModalConfigStepGoogle from "../ModalsConfigSteps/ModalConfigStepGoogle";
import ModalConfigStepInstagram from "../ModalsConfigSteps/ModalConfigStepInstagram";
import ModalConfigStepNps from "../ModalsConfigSteps/ModalConfigStepNps";
import ModalConfigStepTikTok from "../ModalsConfigSteps/ModalConfigStepTikTok";
import ModalConfigStepTripAdvisor from "../ModalsConfigSteps/ModalConfigStepTripAdvisor";
import ModalConfigStepDownloadApp from "../ModalsConfigSteps/ModalConfigStepDownloadApp";
import ModalConfigStepSurvey from "../ModalsConfigSteps/ModalConfigStepSurvey";
import ModalConfigStepReferral from "../ModalsConfigSteps/ModalConfigStepReferral";
import ModalConfigStepTwitter from "../ModalsConfigSteps/ModalConfigStepTwitter";
import ModalConfigStepNewsletterContact from "../ModalsConfigSteps/ModalsConfigStepsNewsletter/ModalConfigStepNewsletterContact";
import ModalConfigStepNewsletterMail from "../ModalsConfigSteps/ModalsConfigStepsNewsletter/ModalConfigStepNewsletterMail";
import ModalConfigStepNewsletterName from "../ModalsConfigSteps/ModalsConfigStepsNewsletter/ModalConfigStepNewsletterName";
import ModalConfigStepNewsletterPhone from "../ModalsConfigSteps/ModalsConfigStepsNewsletter/ModalConfigStepNewsletterPhone";
import ModalConfigPickRestaurant from "../ModalConfigPickRestaurant";
import {
  getNumberOfSteps,
  onRestaurantSelect,
} from "./core/modalConfig.helpers";
import ModalConfigStepGoogleB from "../ModalsConfigSteps/ModalConfigStepGoogleB";
import { INewsletterContext, IProps } from "./core/modalConfig.types";
import ModalAlreadyParticipated from "../ModalAlreadyParticipated";
import { NewsletterContext } from "./core/modalConfig.context";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { useNavigate } from "react-router-dom";
import ModalConfigStepFacebook from "../ModalsConfigSteps/ModalConfigStepFacebook";
import { isDev } from "utils";

export { NewsletterContext as ModalConfigNewsleterContext } from "./core/modalConfig.context";

const ModalConfig: FC<IProps> = ({ marketing, hideModal }) => {
  const { userData, game, configuration } = useSelector(
    (state: IConfigurationState) => state
  );
  const storeUser = userData.user;
  const numberOfSteps = getNumberOfSteps(marketing);
  const [firstName, setFirstName] = useState<string>(storeUser.firstName || "");
  const [email, setEmail] = useState<string>(storeUser.email || "");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    storeUser.phoneNumber || ""
  );
  const navigate = useNavigate();

  const [optIn, setOptIn] = useState<boolean>(storeUser.optIn || false);

  const newsletterContext: INewsletterContext = {
    firstName,
    setFirstName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    optIn,
    setOptIn,
  };

  const newsletterOptionalSteps = [
    { id: 1, component: <ModalConfigStepNewsletterName key={1} /> },
    { id: 2, component: <ModalConfigStepNewsletterMail key={2} /> },
    { id: 3, component: <ModalConfigStepNewsletterPhone key={3} /> },
  ];
  const stepConfig = configuration?.s?.dh || [2];
  //Todo: api gives it to us
  const stepConfigFallBackMail =
    stepConfig.indexOf(2) > -1 ? stepConfig : [...stepConfig, 2];
  const campaignNewsletterSteps = stepConfigFallBackMail.filter(
    (step) => step !== 0
  );

  // If we have ets (special case), we need to display the restaurant picker
  if (configuration?.ets?.length) {
    return (
      <StepNavigation numberOfSteps={0}>
        <ModalConfigPickRestaurant
          onRestaurantSelected={(index: number) => {
            onRestaurantSelect(configuration.ets[index], navigate);
          }}
          hideModal={hideModal}
          establishments={configuration.ets}
        />
      </StepNavigation>
    );
  }
  // If game.canPlayAgain?.c is null/undefined display nothing (waiting state)
  if (game?.canPlayAgain?.c == null && !isDev()) {
    return <></>;
  }

  // Already participated received from api
  if (game?.canPlayAgain?.c === false && !isDev()) {
    return <ModalAlreadyParticipated />;
  }

  return (
    <StepNavigation numberOfSteps={numberOfSteps}>
      {marketing === Marketings.google && (
        <ModalConfigStepGoogle hideModal={hideModal} />
      )}
      {marketing === Marketings.instagram && (
        <ModalConfigStepInstagram hideModal={hideModal} />
      )}
      {marketing === Marketings.tiktok && (
        <ModalConfigStepTikTok hideModal={hideModal} />
      )}
      {marketing === Marketings.facebook && (
        <ModalConfigStepFacebook hideModal={hideModal} />
      )}
      {marketing === Marketings.tripAdvisor && (
        <ModalConfigStepTripAdvisor hideModal={hideModal} />
      )}
      {marketing === Marketings.downloadApp && (
        <ModalConfigStepDownloadApp hideModal={hideModal} />
      )}
      {marketing === Marketings.survey && (
        <ModalConfigStepSurvey hideModal={hideModal} />
      )}
      {marketing === Marketings.nps && (
        <ModalConfigStepNps hideModal={hideModal} />
      )}
      {marketing === Marketings.referral && (
        <ModalConfigStepReferral hideModal={hideModal} />
      )}
      {marketing === Marketings.twitter && (
        <ModalConfigStepTwitter hideModal={hideModal} />
      )}
      {marketing === Marketings.googlePlace && (
        <ModalConfigStepGoogleB hideModal={hideModal} />
      )}
      {marketing === Marketings.newsletter && (
        <NewsletterContext.Provider value={newsletterContext}>
          {newsletterOptionalSteps
            .filter((step) => campaignNewsletterSteps.includes(step.id))
            .map((matchingStep, index) =>
              cloneElement(matchingStep.component, {
                stepOrder: 1 + index,
              })
            )}
          <ModalConfigStepNewsletterContact
            hideModal={hideModal}
            stepOrder={(campaignNewsletterSteps.length || 0) + 1}
          />
        </NewsletterContext.Provider>
      )}
    </StepNavigation>
  );
};

export default ModalConfig;
