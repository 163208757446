import { useEffect } from "react";
import { IHook } from "./ui.types";

export const useUi: IHook = ({ formRef, setIsFormValid }) => {
  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    setIsFormValid(formRef.current.checkValidity());
  });
};
