import { Dispatch, MouseEventHandler } from "react";
import { NavigateFunction } from "react-router-dom";
import { resetGame } from "redux/game/game.actions";

export const handleCTAClick = (
  navigate: NavigateFunction,
  dispatch: Dispatch<any>,
  entityId: string
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    dispatch(resetGame(entityId));
    navigate(`/${entityId}`);
  };

  return handler;
};
