import { FC, useRef, useState } from "react";
import { getFixedLengthArray } from "utils";
import StepNavigationStep from "./composition/StepNavigationStep";
import { Context } from "./core/stepNavigation.context";
import { nextStep, previousStep } from "./core/stepNavigation.helpers";
import { useStepNavigation } from "./core/stepNavigation.hook";
import { IComposition, IContext, IProps } from "./core/stepNavigation.types";

export { Context as StepNavigationContext } from "./core/stepNavigation.context";
export type { IContext as IStepNavigationContext } from "./core/stepNavigation.types";

const StepNavigation: FC<IProps> & IComposition = ({
  children,
  numberOfSteps,
  className,
  hideSteps = false,
}) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const iconsArray = getFixedLengthArray(numberOfSteps);
  const reference = useRef<HTMLDivElement>(null);

  const context: IContext = {
    activeStep,
    setActiveStep,
    nextStep: nextStep(activeStep, setActiveStep, numberOfSteps, reference),
    previousStep: previousStep(
      activeStep,
      setActiveStep,
      numberOfSteps,
      reference
    ),
  };

  useStepNavigation({ activeStep, numberOfSteps });

  return (
    <Context.Provider value={context}>
      <div ref={reference} className={`step-navigation ${className || ""}`}>
        {children}
        {!hideSteps && (
          <div className="step-navigation__icons f f-center mt-5">
            {iconsArray.map((_, index) => {
              const isLast = index === iconsArray.length;
              const isActive = index + 1 === activeStep;

              return (
                <div
                  key={index}
                  className={`step-navigation__icon ${isLast ? "" : "mr-1"} ${
                    isActive ? "step-navigation__icon--active" : ""
                  }`}
                ></div>
              );
            })}
          </div>
        )}
      </div>
    </Context.Provider>
  );
};

StepNavigation.Step = StepNavigationStep;

export default StepNavigation;
