import { ChangeEventHandler } from "react";
import { IProps } from "./textarea.types";

export const handleChange = (
  setValue: IProps["setValue"]
): ChangeEventHandler<HTMLTextAreaElement> => {
  const handler: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.target.value);
  };

  return handler;
};
