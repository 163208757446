import { Dispatch, RefObject, SetStateAction } from "react";
import { IAPIConfiguration } from "redux/configuration/configuration.interface";
import { ISpinGameResult } from "components/SpinGame/SpinGame";

export interface IContext {
  isVisible: boolean;
  showModal: () => void;
  hideModal: () => void;
  reference: RefObject<HTMLDivElement>;
}

export enum Marketings {
  google = "google",
  freeQuestion = "free-question",
  instagram = "instagram",
  tiktok = "tiktok",
  nps = "nps",
  referral = "referral",
  contentCreation = "content-creation",
  twitter = "twitter",
  newsletter = "newsletter",
  googlePlace = "google-place",
  facebook = "facebook",
  tripAdvisor = "trip-advisor",
  downloadApp = "dl-app",
  survey = "survey",
  selectRestaurant = "select-restaurant",
}

export type IHook = (params: {
  startGame: () => void;
  setFakeResults: Dispatch<SetStateAction<ISpinGameResult>>;
  entityId: string | undefined;
  canPlayAgain: boolean | null;
  session: string | null;
  isReady: boolean;
  configuration: IAPIConfiguration | null;
  objective: number | undefined;
}) => void;
