import { useContext, FC } from "react";
import { StepNavigationContext } from "components/StepNavigation";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleCTAClick } from "./core/modalConfigStepNewsletterContact.helpers";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import CheckboxInput from "components/Inputs/CheckboxInput";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { ModalConfigNewsleterContext } from "components/Modals/ModalConfig";
import { IProps } from "./core/modalConfigStepNewsletterContact.types";
import { useNavigate } from "react-router";

const ModalConfigStepNewsletterContact: FC<IProps> = ({
  hideModal,
  stepOrder,
}) => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const { t } = useTranslation("modals", { keyPrefix: "newsletter.contact" });
  const navigate = useNavigate();
  const { configuration } = useSelector((state: IConfigurationState) => state);

  const dispatch = useDispatch();
  const newsletterContext = useContext(ModalConfigNewsleterContext);
  const { optIn, setOptIn } = { ...newsletterContext };

  return stepNavigationContext && optIn !== undefined && setOptIn ? (
    <StepNavigationStep className="modal__content" step={stepOrder}>
      <div className="modal__img">
        <img
          className="modal__img__newsletter"
          src="/static/images/modals/newsletter.png"
          alt="newsletter icon"
        />
      </div>
      <h2 className="modal__title">{t("title")}</h2>
      <CheckboxInput
        className="mb-3"
        checked={optIn}
        setChecked={setOptIn}
        label={t("contactAgreement", { restaurant: configuration?.dn })}
      />
      <Button
        text={t("CTA")}
        icon={Icons.chevronRight}
        onClick={handleCTAClick(hideModal, navigate, optIn, dispatch)}
        className="modal__btn mt-0"
      />
    </StepNavigationStep>
  ) : null;
};

export default ModalConfigStepNewsletterContact;
