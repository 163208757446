import { useContext, useState, FC } from "react";
import { StepNavigationContext } from "components/StepNavigation";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleNextStepClick } from "./core/modalConfigStepNewsletterName.helpers";
import Button from "components/Inputs/Button";
import TextInput from "components/Inputs/TextInput";
import { Icons } from "utils/constants/icons";
import { Trans, useTranslation } from "react-i18next";
import { ModalConfigNewsleterContext } from "components/Modals/ModalConfig";
import { IProps } from "./core/modalConfigStepNewsletterName.types";
import { useDispatch } from "react-redux";

const ModalConfigStepNewsletterName: FC<IProps> = ({ stepOrder }) => {
  const stepNavigationContext = useContext(StepNavigationContext);
  const { t } = useTranslation("modals", { keyPrefix: "newsletter.name" });
  const newsletterContext = useContext(ModalConfigNewsleterContext);
  const { firstName, setFirstName } = { ...newsletterContext };
  const dispatch = useDispatch();

  const [tempFirstName, setTempFirstName] = useState<string>(firstName || "");

  return stepNavigationContext ? (
    <StepNavigationStep className="modal__content" step={stepOrder}>
      <div className="modal__img">
        <img
          className="modal__img__newsletter"
          src="/static/images/modals/newsletter.png"
          alt="newsletter icon"
        />
      </div>
      <h2 className="modal__title">{t("title")}</h2>
      <p>
        <Trans
          ns="modals"
          i18nKey="newsletter.name.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <TextInput
        value={tempFirstName}
        setValue={setTempFirstName}
        placeholder={t("placeholder")}
        label={t("label")}
        className="modal__input"
      />
      <Button
        text={t("CTA")}
        onClick={handleNextStepClick(
          stepNavigationContext,
          setFirstName,
          tempFirstName,
          dispatch
        )}
        icon={Icons.chevronRight}
        className="modal__btn mt-0"
        disabled={tempFirstName.length < 3}
      />
    </StepNavigationStep>
  ) : null;
};

export default ModalConfigStepNewsletterName;
