import { MouseEventHandler } from "react";
import { NavigateFunction } from "react-router-dom";
import { ROUTES, Routes } from "utils/constants/router";
import { IProps } from "./modalConfigStepReferral.types";

export const handleCTAClick = (
  hideModal: IProps["hideModal"],
  navigate: NavigateFunction
): MouseEventHandler => {
  const handler: MouseEventHandler = async () => {
    const data: ShareData = {
      url: "https://developer.mozilla.org/fr/docs/Web/API/Navigator/share",
    };

    await navigator.share(data);

    hideModal();

    setTimeout(() => {
      navigate(`../${ROUTES.get(Routes.game).path}`);
    }, 400);
  };

  return handler;
};
