import { useEffect } from "react";
import { IHook } from "./stepNavigation.types";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { track } from "utils";
import { MyliEventType } from "utils/types/event";
import { useParams } from "react-router-dom";

export const useStepNavigation: IHook = ({ activeStep, numberOfSteps }) => {
  const { session } = useSelector((state: IConfigurationState) => state);
  const { entityId } = useParams();

  useEffect(() => {
    if (session && numberOfSteps && numberOfSteps > 2) {
      track(MyliEventType.DATA_COLLECT_STEP, {
        campaignId: entityId,
        activeStep,
        numberOfSteps,
      });
    }
  }, [activeStep, numberOfSteps, entityId, session]);
};
