import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fr from "./fr.json";
import en from "./en.json";
import es from "./es.json";
import vi from "./vi.json";
import it from "./it.json";
import ca from "./ca.json";
import pt from "./pt.json";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/vi";
import "dayjs/locale/es";
import "dayjs/locale/ca";
import "dayjs/locale/it";
import "dayjs/locale/en";
import "dayjs/locale/pt";

import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== "production",
    lng: "fr",
    fallbackLng: "fr",
    resources: {
      fr,
      en,
      es,
      vi,
      it,
      ca,
      pt,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
