import { FC } from "react";
import { getIdFromLabel } from "utils";
import { handleChange } from "./core/textarea.helpers";
import { IProps } from "./core/textarea.types";

const TextareaInput: FC<IProps> = ({
  rows,
  value,
  setValue,
  placeholder,
  className,
  label,
  required = false,
}) => {
  const id = getIdFromLabel(label);

  return (
    <div className={`input input--textarea ${className || ""}`}>
      <label className="input__label" htmlFor={id}>
        {label}
      </label>
      <textarea
        className="input__input"
        placeholder={placeholder}
        onChange={handleChange(setValue)}
        rows={rows}
        id={id}
        value={value}
        required={required}
      />
    </div>
  );
};

export default TextareaInput;
