import { ICollectContext } from "pages/Collect";
import { Dispatch, MouseEventHandler } from "react";
import { setRewardValidation } from "redux/game/game.actions";

export const handleCTAClick = (
  hideModal: () => void,
  hideModalCollectInstructions: () => void,
  dispatch: Dispatch<any>,
  rewardId: ICollectContext["rewardId"] | undefined,
  companyId: ICollectContext["companyId"] | undefined
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    if (!rewardId || !companyId) {
      return;
    }

    hideModal();

    setTimeout(() => {
      hideModalCollectInstructions();
      dispatch(setRewardValidation(companyId, rewardId));
    }, 400);
  };

  return handler;
};
