import { FC } from "react";
import { useSwiper } from "swiper/react";
import { useCarouselAutoSwiping } from "./core/carouselAutoSwiping.hook";
import { IProps } from "./core/carouselAutoSwiping.types";

const CarouselAutoSwiping: FC<IProps> = ({ speed = 2000 }) => {
  const swiper = useSwiper();
  useCarouselAutoSwiping({ swiper, speed });

  return <></>;
};

export default CarouselAutoSwiping;
