import { RefObject } from "react";

export interface IContext {
  isVisible: boolean;
  showModal: () => void;
  hideModal: () => void;
  reference: RefObject<HTMLDivElement>;
}

export enum Rewards {
  coupon = "coupon",
  prize = "prize",
  loss = "loss",
}
