import { FC, useMemo } from "react";
import { SpinGameSlot } from "./SpinGameSlot";
import { IStandardProps } from "utils/types/standardProps";

import "./spin-game.scss";
import { useSelector } from "react-redux";
import {
  IAPIPicture,
  IConfigurationState,
} from "redux/configuration/configuration.interface";
import { getSpinGameResults } from "utils";

export interface IProps extends IStandardProps {
  isGameStarted: boolean;
}

export type ISpinGameResult = number[];

const SpinGame: FC<IProps> = ({ isGameStarted, className }) => {
  let pictures = useSelector(
    (state: IConfigurationState) => state?.configuration?.s?.pictures
  );

  let won = useSelector((state: IConfigurationState) => state.game?.won);

  const sortedPictures = useMemo<IAPIPicture[]>(() => {
    if (!pictures) {
      return [];
    }
    return pictures.reduce((accu, cur) => {
      if (cur.iw && !cur.il) {
        // Put the success icon atf first position
        accu.unshift(cur);
      } else if (!cur.il) {
        // add all other icon except the loose one
        accu.push(cur);
      }
      return accu;
    }, []);
  }, [pictures]);

  const results = useMemo<ISpinGameResult | undefined>(() => {
    if (won == null || !isGameStarted) {
      return getSpinGameResults(false, sortedPictures.length);
    }

    return getSpinGameResults(won, sortedPictures.length);
  }, [sortedPictures?.length, isGameStarted, won]);

  return (
    <div
      className={`spin-game p-3 f f-ai-center f-jc-space-b ${className || ""}`}
    >
      {results?.length &&
        results.map((_, index) => (
          <SpinGameSlot
            sortedPictures={sortedPictures}
            isSpinning={isGameStarted}
            key={index}
            result={results?.[index]}
            index={index}
          />
        ))}
    </div>
  );
};

export default SpinGame;
