import { FC, useRef } from "react";
import { GRADES } from "./core/customeRange.constants";
import { handleClick, handleTouchMove } from "./core/customRange.helpers";
import { IProps } from "./core/custom-range.types";

export const CustomRange: FC<IProps> = ({ value, setValue, className }) => {
  const reference = useRef<HTMLDivElement>(null);

  return (
    <div className={`custom-range bg-grey-200 p-1 ${className || ""}`}>
      <div
        ref={reference}
        onTouchMove={handleTouchMove(reference, setValue)}
        className="custom-range__content f f-jc-space-a pt-2 pb-2 pl-2"
      >
        {GRADES.map((grade) => (
          <p
            className={`custom-range__grade mb-0 fw-800 ${
              grade === value ? "custom-range__grade--active" : ""
            }`}
            key={grade}
            onClick={handleClick(setValue, grade)}
          >
            {grade}
          </p>
        ))}
      </div>
    </div>
  );
};
