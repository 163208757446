import { FC, MouseEventHandler, useContext, useState } from "react";
import { StepNavigationContext } from "components/StepNavigation";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import TextInput from "components/Inputs/TextInput";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import { Trans, useTranslation } from "react-i18next";
import { ModalClaimPrizeContext } from "components/Modals/ModalClaim";
import { useModalClaimPrizeStepPhone } from "./core/modalClaimPrizeStepPhone.hook";
import { isValidPhoneNumber } from "libphonenumber-js";
import { IProps } from "./core/modalClaimPrizeStepPhone.types";
import CheckboxInput from "components/Inputs/CheckboxInput";
import { useSelector, useDispatch } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { AppThunkDispatch } from "redux/configuration/configuration.types";
import { setUserData } from "redux/user/user.actions";
import { useParams } from "react-router";

const ModalClaimStepPrizePhone: FC<IProps> = ({ step = 4 }) => {
  const configuration = useSelector(
    (state: IConfigurationState) => state.configuration
  );

  const userData = useSelector((state: IConfigurationState) => state.userData);

  const dispatch: AppThunkDispatch = useDispatch();

  const [optInMobile, setOptInMobile] = useState<boolean>(
    !!userData?.user?.optInMobile
  );

  const prizeContext = useContext(ModalClaimPrizeContext);
  const { phoneNumber, setPhoneNumber } = { ...prizeContext };

  const stepNavigationContext = useContext(StepNavigationContext);

  const { t } = useTranslation("modals");

  const [tempPhoneNumber, setTempPhoneNumber] = useState<string>(
    phoneNumber || ""
  );
  const { entityId } = useParams();

  const handleNextStepClick: MouseEventHandler = () => {
    const { nextStep } = stepNavigationContext;
    if (tempPhoneNumber) {
      dispatch(
        setUserData({
          phoneNumber: tempPhoneNumber,
          optInMobile,
          cpid: entityId,
        })
      );
    }
    nextStep();
  };

  useModalClaimPrizeStepPhone({ tempPhoneNumber, setPhoneNumber });

  return stepNavigationContext ? (
    <StepNavigationStep className="modal__content" step={step}>
      <div className="modal__img">
        <img
          src="/static/images/modals/rockstar.png"
          alt="rockstar sign emoji"
        />
      </div>
      <h2 className="modal__title">{t("prize.phone.title")}</h2>
      <p>
        <Trans
          ns="modals"
          i18nKey="prize.phone.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <TextInput
        className="modal__input"
        value={tempPhoneNumber}
        setValue={setTempPhoneNumber}
        placeholder={t("prize.phone.placeholder")}
        label={t("prize.phone.label")}
        type="tel"
      />
      <CheckboxInput
        className="mb-3"
        checked={optInMobile}
        setChecked={setOptInMobile}
        label={t("prize.email.contactAgreement", {
          restaurant: configuration?.dn,
        })}
      />
      <Button
        className="modal__btn mt-0"
        text={t("prize.phone.CTA")}
        onClick={handleNextStepClick}
        icon={Icons.chevronRight}
        disabled={!isValidPhoneNumber(tempPhoneNumber, "FR")}
      />
    </StepNavigationStep>
  ) : null;
};

export default ModalClaimStepPrizePhone;
