import { Dispatch, MouseEventHandler, SetStateAction } from "react";

export const handleTitleClick = (
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    setIsOpen(!isOpen);
  };

  return handler;
};
