import { FC } from "react";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

const ModalCollectFeedbackStepConfirm: FC = () => {
  const { t } = useTranslation("modals", {
    keyPrefix: "collect.feedbackConfirm",
  });
  const { reward } = useSelector((state: IConfigurationState) => state);
  return (
    <StepNavigationStep className="modal__content" step={2}>
      <div className="modal__img">
        <img
          src="/static/images/modals/check-mark.png"
          alt="check mark emoji"
        />
      </div>
      <h2 className="modal__title">{t("title")}</h2>
      <p className="mb-10">{t("subtitle2", { restaurant: reward?.dn })}</p>
      {/*       {reward?.bu && (
        <Button
          className="modal__btn mb-3"
          onClick={handleCTAClick(reward?.bu)}
          text={t("CTA", { website: reward?.bn })}
        />
      )} */}
    </StepNavigationStep>
  );
};

export default ModalCollectFeedbackStepConfirm;
