import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { IAPIConfiguration } from "redux/configuration/configuration.interface";
import { i18n } from "i18next";

export type UseInternationalizationProps = (params: {
  i18n: i18n;
  contents: IAPIConfiguration["contents"] | undefined;
  locale: string;
}) => void;

type SupportedLanguages = "fr" | "en" | "es" | "it" | "vi" | "ca" | "pt";

const localeToLang = {
  fr: "fr",
  gb: "en",
  en: "en",
  es: "es",
  ca: "ca",
  it: "it",
  vi: "vi",
  pt: "pt",
  us: "en",
};

function mapLocaleToLang(locale: string): SupportedLanguages {
  return localeToLang[locale?.toLowerCase()] || "fr";
}

export const useInternationalization: UseInternationalizationProps = ({
  i18n,
  contents,
  locale,
}) => {
  const lang = useMemo(() => {
    return mapLocaleToLang(locale);
  }, [locale]);

  useEffect(() => {
    if (lang) {
      document.documentElement.lang = lang;
      i18n.changeLanguage(lang);
      dayjs.locale(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    if (!contents) {
      return;
    }

    for (const lng in contents) {
      const lngContent = contents[lng].reduce(
        (acc: Record<string, string>, { key, text }) => {
          acc[key] = text;
          return acc;
        },
        {}
      );

      i18n.addResourceBundle(lang, "remote", lngContent, true, true);
    }
  }, [contents, i18n, lang]);
};
