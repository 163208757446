import { FC } from "react";
import { handleClick } from "./core/restaurant.helpers";
import { IProps } from "./core/restaurant.types";

const Restaurant: FC<IProps> = ({
  name,
  address,
  index,
  setSelectedRestaurantIndex,
  hideModal,
  className,
}) => {
  return (
    <div
      onClick={handleClick(setSelectedRestaurantIndex, index, hideModal)}
      className={`f f-direction-column ${className || ""}`}
    >
      <p className="fw-700">{name}</p>
      <p className="c-grey-600 fs-small">{address}</p>
    </div>
  );
};

export default Restaurant;
