import Layout from "components/Layout";
import { FC } from "react";
import { Trans } from "react-i18next";
import { QRCodeCanvas } from "qrcode.react";
import { useLocation } from "react-router-dom";
import { env } from "config/env";
import { IProps } from "./core/desktop.types";

const Desktop: FC<IProps> = ({ isCollect = false }) => {
  const location = useLocation();
  const qrCodeRedirectionUrl = `${env.baseUrl}${location.pathname}${window.location.search}`;

  return (
    <Layout hideFooter isDesktop>
      <div className="f f-direction-column f-ai-center desktop__container">
        <div className="f f-direction-column f-ai-center pl-4 pr-4">
          <p className="c-primary desktop__bigTitle fw-700">
            <Trans ns="desktop" i18nKey="title" />
          </p>
          <p className="c-primary desktop__lowTitle mb-5">
            <Trans
              ns="desktop"
              i18nKey={isCollect ? "introCollect" : "introHome"}
            />
          </p>
        </div>
        <div className="f f-jc-center">
          <div className="desktop__qrCodeContainer f f-direction-column f-ai-center p-8 ft-center">
            <div className="desktop__qrCodeImg">
              <QRCodeCanvas value={qrCodeRedirectionUrl} size={246} />
            </div>
            <p className="mt-4 c-primary fw-600">
              <Trans ns="desktop" i18nKey="qrCode" />
            </p>
          </div>
        </div>
        <img
          className="desktop__doodle desktop__doodle--1"
          src="/static/images/doodles/doodle-1.png"
          alt="doodle"
        />
        <img
          className="desktop__doodle desktop__doodle--2"
          src="/static/images/doodles/doodle-4.png"
          alt="doodle"
        />
        <img
          className="desktop__doodle desktop__doodle--3"
          src="/static/images/doodles/doodle-1.png"
          alt="doodle"
        />
        <img
          className="desktop__doodle desktop__doodle--4"
          src="/static/images/doodles/doodle-1.png"
          alt="doodle"
        />
        <img
          className="desktop__doodle desktop__doodle--5"
          src="/static/images/doodles/doodle-4.png"
          alt="doodle"
        />
        <img
          className="desktop__doodle desktop__doodle--6"
          src="/static/images/doodles/doodle-4.png"
          alt="doodle"
        />
        <img
          className="desktop__doodle desktop__doodle--7"
          src="/static/images/doodles/doodle-4.png"
          alt="doodle"
        />
      </div>
    </Layout>
  );
};

export default Desktop;
