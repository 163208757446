import { IDispatch, store } from "redux/store";
import { ActionTypes } from "./user.types";
import { Company, User } from "./user.interface";
import axios from "services/axios.service";
import { endpoints } from "config/endpoints";

export const setCurrentCompany =
  (company: Company) => async (dispatch: IDispatch) => {
    dispatch({
      type: ActionTypes.SET_SELECTED_COMPANY,
      payload: company,
    });
  };

export const setUserData = (user: User) => async (dispatch: IDispatch) => {
  const currentUser = store.getState()?.userData?.user || {};
  const newUser = { ...currentUser, ...user };

  try {
    await axios.api.put(endpoints.api.user.setData, {
      ...newUser,
    });
  } catch (err) {
    console.error("setUserData - Error while putting data", err);
  }

  dispatch({
    type: ActionTypes.SET_USER_DATA,
    payload: newUser,
  });
};

export const setNPS =
  (score: number, entityId: string) => async (dispatch: IDispatch) => {
    // TODO: change to POST WHEN AVAILABLE
    await axios.api.post(endpoints.api.nps, {
      cid: entityId,
      score,
    });

    dispatch({
      type: ActionTypes.SET_NPS_SCORE,
      payload: score,
    });
  };

export const setUserSatisfaction =
  (entityId: string, isHappy: boolean) => async (dispatch: IDispatch) => {
    await axios.api.post(endpoints.api.user.setUserSatisfaction, {
      rid: entityId,
      ih: isHappy,
    });

    dispatch({
      type: ActionTypes.SET_SATISFACTION_INQUIRY,
      payload: isHappy,
    });
  };
