import TextInput from "components/Inputs/TextInput";
import { FC, useRef, useState } from "react";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import { handleFormSubmit, mouseEventHandler } from "./core/ui.helpers";
import TextareaInput from "components/Inputs/TextareaInput";
import CheckboxInput from "components/Inputs/CheckboxInput";
import SelectInput from "components/Inputs/SelectInput";
import { UI_SELECT_OPTIONS } from "./core/ui.constants";
import { useUi } from "./core/ui.hook";
import FakeInput from "components/Inputs/FakeInput";
import { CustomRange } from "components/CustomRange";
import ExpandPanel from "components/ExpandPanel";

const Ui: FC = () => {
  const [text, setText] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [grade, setGrade] = useState<number>();
  const [isFormValid, setIsFormValid] = useState<boolean>();
  const formRef = useRef<HTMLFormElement>(null);
  const [fake] = useState<string>("");

  useUi({ formRef, setIsFormValid });

  return (
    <div className="p-5 f f-direction-column">
      <h1 className="mb-5">UI</h1>
      <h2 className="mb-3">Buttons</h2>
      <Button
        className="mb-3"
        onClick={mouseEventHandler}
        text="button primary"
      />
      <Button
        className="mb-3"
        onClick={mouseEventHandler}
        text="button google"
        appearance="google"
      />
      <Button
        className="mb-3"
        onClick={mouseEventHandler}
        text="button instagram"
        appearance="instagram"
      />
      <Button
        className="mb-3"
        onClick={mouseEventHandler}
        text="button icon"
        appearance="primary"
        icon={Icons.chevronRight}
      />
      <Button
        className="mb-4"
        onClick={mouseEventHandler}
        text="button loading"
        appearance="google"
        loading
      />
      <h2 className="mb-3">Inputs</h2>
      <form ref={formRef} className="f f-direction-column mb-4">
        <TextInput
          placeholder="Text Input"
          value={text}
          setValue={setText}
          className="mb-3"
          label="test text input"
          required
        />
        <TextInput
          placeholder="Tel Input"
          value={phone}
          setValue={setPhone}
          type="tel"
          className="mb-3"
          label="test tel input"
          required
        />
        <TextareaInput
          placeholder="Textarea Input"
          value={message}
          setValue={setMessage}
          rows={5}
          className="mb-3"
          label="test textarea input"
          required
        />
        <SelectInput
          options={UI_SELECT_OPTIONS}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          placeholder="Select Input"
          label="test select input"
          className="mb-3"
          required
        />
        <FakeInput
          value={fake}
          label="test fake input"
          className="mb-3"
          placeholder="Fake Input"
          onClick={() => {}}
        />
        <CheckboxInput
          checked={checked}
          setChecked={setChecked}
          label="Checkbox Input"
          className="mb-3"
        />
        <CustomRange value={grade} setValue={setGrade} />
        <Button
          disabled={!isFormValid || !checked}
          onClick={handleFormSubmit(
            text,
            phone,
            message,
            selectedIndex !== undefined
              ? UI_SELECT_OPTIONS[selectedIndex]
              : undefined,
            checked
          )}
          text="Submit"
          className="mt-4"
        />
      </form>
      <ExpandPanel
        title="Je n'arrive pas a lancer la machine"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vestibulum fringilla at nulla malesuada. Vestibulum nunc pellentesque id feugiat auctor lectus ultricies. Id vitae semper maecenas ut orci, enim neque varius."
      />
      <ExpandPanel
        title="Je n'arrive pas mettre un avis Google"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vestibulum fringilla at nulla malesuada. Vestibulum nunc pellentesque id feugiat auctor lectus ultricies. Id vitae semper maecenas ut orci, enim neque varius."
      />
    </div>
  );
};

export default Ui;
