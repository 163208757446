import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { useEffect } from "react";
import { IHook } from "./modalConfigStepNewsletterPhone.types";

export const useModalConfigStepNewsletterPhone: IHook = ({
  tempPhoneNumber,
  setPhoneNumber,
}) => {
  useEffect(() => {
    if (!isValidPhoneNumber(tempPhoneNumber, "FR") || !setPhoneNumber) {
      return;
    }

    const phoneNumber = parsePhoneNumber(tempPhoneNumber, "FR");

    setPhoneNumber(phoneNumber.number);
  }, [setPhoneNumber, tempPhoneNumber]);
};
