import { MouseEventHandler, Dispatch } from "react";
import { IStepNavigationContext } from "components/StepNavigation";
import { INewsletterContext } from "components/Modals/ModalConfig/core/modalConfig.types";
import { setUserData } from "redux/user/user.actions";

export const handleNextStepClick = (
  stepNavigationContext: IStepNavigationContext,
  setFirstName: INewsletterContext["setFirstName"] | undefined,
  tempFirstName: string,
  dispatch: Dispatch<any>
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    setFirstName(tempFirstName);
    dispatch(setUserData({ firstName: tempFirstName }));
    stepNavigationContext.nextStep();
  };

  return handler;
};
