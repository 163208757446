import { INewsletterContext } from "components/Modals/ModalConfig/core/modalConfig.types";
import { IStepNavigationContext } from "components/StepNavigation";
import { MouseEventHandler } from "react";

export const handleNextStepClick = (
  stepNavigationContext: IStepNavigationContext,
  setPhoneNumber: INewsletterContext["setPhoneNumber"] | undefined,
  tempPhoneNumber: string
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    if (!setPhoneNumber) {
      return;
    }

    setPhoneNumber(tempPhoneNumber);
    stepNavigationContext.nextStep();
  };

  return handler;
};
