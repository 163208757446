export enum Icons {
  chevronRight = "chevron-right",
  chevronDown = "chevron-down",
  chevronDownBlack = "chevron-down-black",
  geolocate = "geolocate",
  loading = "loading",
  search = "search",
  closeGrey = "close-grey",
  tab = "tab",
}
