import { useEffect } from "react";
import { IHook } from "./HomeSelectrestaurant.types";

export const useHomeSelectRestaurant: IHook = ({ showModal }) => {
  useEffect(() => {
    if (!showModal) {
      return;
    }

    showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
