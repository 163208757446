import {
  GameRewardValidationResponse,
  GameRewardInfo,
  IAPIGame,
  GamePlayAgain,
} from "./game.interface";

export enum ActionTypes {
  SET_GAME_CAN_PLAY_AGAIN = "SET_GAME_CAN_PLAY_AGAIN",
  SET_GAME_PLAY = "SET_GAME_PLAY",
  RESET_GAME = "RESET_GAME",
  SET_GAME_REWARD_VALIDATED = "SET_GAME_REWARD_VALIDATED",
  SET_GAME_REWARD_INFO = "SET_GAME_REWARD_INFO",
  SET_GAME_REWARD_INFO_ERROR = "SET_GAME_REWARD_INFO_ERROR",
}

interface GameSetCanPlayAgainAction {
  type: typeof ActionTypes.SET_GAME_CAN_PLAY_AGAIN;
  payload: GamePlayAgain;
}

interface GameSetPlayAction {
  type: typeof ActionTypes.SET_GAME_PLAY;
  payload: IAPIGame;
}

interface GameReset {
  type: typeof ActionTypes.RESET_GAME;
  payload: void;
}

interface GameSetRewardValidationAction {
  type: typeof ActionTypes.SET_GAME_REWARD_VALIDATED;
  payload: GameRewardValidationResponse;
}

interface GameSetRewardInfoAction {
  type: typeof ActionTypes.SET_GAME_REWARD_INFO;
  payload: GameRewardInfo;
}

interface GameSetRewardInfoErrorAction {
  type: typeof ActionTypes.SET_GAME_REWARD_INFO_ERROR;
  payload: GameRewardInfo;
}

export type GameActionTypes =
  | GameSetCanPlayAgainAction
  | GameSetPlayAction
  | GameReset
  | GameSetRewardValidationAction
  | GameSetRewardInfoAction
  | GameSetRewardInfoErrorAction;
