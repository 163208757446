import Color from "color";
import { IAPIPicture } from "redux/configuration/configuration.interface";
import { Duration } from "redux/game/game.interface";
import dayJs from "dayjs";
import { MyliEvent, MyliEventType } from "./types/event";
import regexes from "./constants/regex";
import { ISpinGameResult } from "../components/SpinGame/SpinGame";
//eslint-disable-next-line
import { detect } from "detect-browser";

export const objectToQueryParameters = (params: any) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const track = (eventType: MyliEventType, content: MyliEvent) => {
  if (typeof myli_push !== "undefined") {
    myli_push.track(eventType, content);
  }
};

export const getFixedLengthArray = (length: number) => {
  return Array.from({ length });
};

export const getIdFromLabel = (str: string): string => {
  return str.toLowerCase().replaceAll(" ", "-");
};

export const initColors = (primaryColor: string) => {
  const color = Color(primaryColor);

  document.documentElement.style.setProperty("--primary", color.hex());
  document.documentElement.style.setProperty(
    "--primary-light",
    color
      .saturate(2)
      .mix(Color("white"), 0.87)
      .rotate(color.isDark() ? -10 : 10)
      .hex()
  );
  document.documentElement.style.setProperty(
    "--primary-x-light",
    color
      .saturate(2)
      .mix(Color("white"), 0.92)
      .rotate(color.isDark() ? -10 : 10)
      .hex()
  );
  document.documentElement.style.setProperty(
    "--primary-dark",
    color
      .saturate(-0.3)
      .mix(Color("white"), 0)
      .rotate(color.isDark() ? -5 : 5)
      .darken(0.2)
      .hex()
  );
};

export const shuffleArray = (array: any) => {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isDev = () => {
  return process.env.NODE_ENV !== "production";
};

export const getWinningPicture = (pictures: IAPIPicture[] | undefined) => {
  if (!pictures) {
    return;
  }

  for (const picture of pictures) {
    if (picture.iw && !picture.il) {
      return picture;
    }
  }
};

export const getTwoDigitsNumber = (int: number): string => {
  return int.toLocaleString(undefined, { minimumIntegerDigits: 2 });
};

export const getInitials = (str: string) => {
  const words = str.split(" ");
  return `${words[0].charAt(0)}${words[words.length - 1].charAt(0)}`;
};

export const getSpinGameResults = (
  isWin: boolean | undefined,
  nbResults: number
): ISpinGameResult => {
  if (isWin) {
    return [0, 0, 0];
  }

  const availableDraws = new Map();

  for (let cpt = 0; cpt < nbResults; cpt++) {
    availableDraws.set(cpt, { alreadySelected: false });
  }

  const draw = () => {
    const pool = Array.from(availableDraws.keys());
    const draw = getRandomIntInclusive(0, pool.length - 1);
    const result = pool[draw];

    if (!availableDraws.get(result)?.alreadySelected) {
      availableDraws.set(result, { alreadySelected: true });
    } else {
      availableDraws.delete(result);
    }

    return result;
  };

  return [draw(), draw(), draw()];
};

/* eslint-disable no-useless-escape */
export const deviceIsMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor);
  return check;
};

export const isSameObject = (source: any, toCompare: any) => {
  return JSON.stringify(source) === JSON.stringify(toCompare);
};

export const getPeriod = (period: Duration) => {
  switch (period) {
    case Duration.DAILY:
      return "day";
    case Duration.WEEKLY:
      return "week";
    case Duration.MONTHLY:
      return "month";
  }
};

export const getPrizeExpirationDate = (
  duration: number | undefined,
  period: Duration | undefined,
  startDate: string | undefined
) => {
  if (!duration || !period) {
    return null;
  }
  const periodtoAdd = getPeriod(period);

  return dayJs(startDate).add(duration, periodtoAdd).format("DD/MM/YYYY");
};

export const isEmailValid = (email: string) => {
  if (email.match(regexes.email)) {
    const splittedEmail = email.split("@");

    return splittedEmail[0].length <= 64 && splittedEmail[1].length <= 255;
  }
  return false;
};

export const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "unknown";
};

export const getBrowserName = () => {
  const { userAgent } = navigator;
  let browser = "chrome";

  //@ts-ignore
  if (navigator?.brave && navigator?.brave?.isBrave()) {
    browser = "brave";
  } else if (
    userAgent.match(/Opera|OPR\//) ||
    userAgent.includes("OPR") ||
    userAgent.includes("OPiOS") ||
    userAgent.includes("OPT")
  ) {
    browser = "opera";
  } else if (
    /firefox/i.test(userAgent.toLowerCase()) ||
    userAgent.includes("FxiOS")
  ) {
    browser = "firefox";
  } else if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    if (userAgent.includes("CriOS")) browser = "chrome";
    else browser = "safari";
  }

  return browser;
};
