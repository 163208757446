import Icon from "components/Icon";
import { FC } from "react";
import { Icons } from "utils/constants/icons";
import { getIdFromLabel } from "utils";
import { PLACEHOLDER_VALUE } from "./core/selectInput.constants";
import { handleChange } from "./core/selectInput.helpers";
import { IProps } from "./core/selectInput.types";

const SelectInput: FC<IProps> = ({
  className,
  setSelectedIndex,
  selectedIndex,
  options,
  label,
  placeholder,
  required = false,
}) => {
  const id = getIdFromLabel(label);

  return (
    <div className={`input input--select ${className || ""}`}>
      <label className="input__label" htmlFor={id}>
        {label}
      </label>
      <select
        required={required}
        value={selectedIndex !== undefined ? selectedIndex : PLACEHOLDER_VALUE}
        className={`input__input ${
          selectedIndex === undefined ? "input__input--placeholder" : ""
        }`}
        id={id}
        onChange={handleChange(setSelectedIndex)}
      >
        <option
          className="input__input-option"
          value={PLACEHOLDER_VALUE}
          disabled
        >
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option className="input__input-option" value={index} key={option}>
            {option}
          </option>
        ))}
      </select>
      <Icon className="input__icon" icon={Icons.chevronDownBlack} />
    </div>
  );
};

export default SelectInput;
