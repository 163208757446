import { Dispatch, MouseEventHandler } from "react";
import { IStepNavigationContext } from "components/StepNavigation";
import { setUserSatisfaction } from "redux/user/user.actions";

export const handleSurveyClick = (
  isHappy: boolean,
  stepNavigationContext: IStepNavigationContext,
  dispatch: Dispatch<any>,
  entityId: string | undefined
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    if (!entityId) {
      return;
    }

    dispatch(setUserSatisfaction(entityId, isHappy));
    stepNavigationContext.nextStep();
  };

  return handler;
};
