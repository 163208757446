import { useEffect } from "react";
import { initColors } from "utils";
import { IHook } from "./useColor.types";

export const useColor: IHook = ({ primaryColor }) => {
  useEffect(() => {
    if (primaryColor) {
      initColors(primaryColor);
    }
  }, [primaryColor]);
};
