import Icon from "components/Icon";
import { FC } from "react";
import { Icons } from "utils/constants/icons";
import { getIdFromLabel } from "utils";
import { IProps } from "./core/fakeInput.types";

const FakeInput: FC<IProps> = ({
  className,
  placeholder,
  value,
  label,
  onClick,
  required = false,
}) => {
  const id = getIdFromLabel(label);

  return (
    <div onClick={onClick} className={`input input--fake ${className || ""}`}>
      <label htmlFor={id} className="input__label">
        {label}
      </label>
      <input
        readOnly
        required={required}
        value={value}
        placeholder={placeholder}
        className="input__input"
        type="text"
        id={id}
      />
      <Icon className="input__icon" icon={Icons.search} />
      <div className="input__icon input__arrow-up"></div>
      <div className="input__icon input__arrow-down"></div>
    </div>
  );
};

export default FakeInput;
