import { useEffect } from "react";
import { IHook } from "./usePlayEvent.types";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { track } from "utils";
import { MyliEventType } from "utils/types/event";

export const UsePlayEvent: IHook = ({ campaignId, isPlaying, hasWon }) => {
  const { session } = useSelector((state: IConfigurationState) => state);

  useEffect(() => {
    if (session && campaignId && isPlaying && hasWon !== null) {
      track(MyliEventType.GAME_PLAY, { campaignId, hasWon });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, session, isPlaying, hasWon]);
};
